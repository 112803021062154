import { REGISTER_PAGE_VIEW } from "../actions/analytics";
import { isGTMEnabled } from '../config/app';
import GTM from '../libraries/gtm';
import { formatTitle } from "../helpers/utils";

let lastViewedPage = '';
const googleAnalyticsMiddleware = ({ getState }) => {
    return next => action => {
        const { type, page, title } = action;

        // if state.user.isNeedUpdate=true the screen loader will be shown
        // and a page will re-render, to prevent double events this check
        // has to be here
        if (isGTMEnabled && type === REGISTER_PAGE_VIEW) {
            const pageToLog = page ? `/${page}` : (window.location.pathname + window.location.search);
            const pageTitle = title ? formatTitle(title) : document.title;

            if (pageToLog !== lastViewedPage) {
                lastViewedPage = pageToLog;

                GTM.dataLayer( {
                    'event': 'pageView',
                    'pagePath': pageToLog,
                    'pageTitle': pageTitle,
                });
            }
        }

        return next(action);
    }
};

export default googleAnalyticsMiddleware;
