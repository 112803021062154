import React from "react";
import PropTypes from 'prop-types';

export default class BlankLink extends React.PureComponent {
    render() {
        let { link, text, className, children } = this.props;

        return (
            <a
                href={link}
                className={className}
                rel={'noopener noreferrer'}
                target={'_blank'}>
                {text}{children}
            </a>
        );
    }
}

BlankLink.propTypes = {
    children: PropTypes.node,
    text: PropTypes.string,
    link: PropTypes.string.isRequired,
    className: PropTypes.string,
};
