import { connect } from 'react-redux';
import ScreenLoader from '../../../components/elements/loaders/ScreenLoader';

const mapStateToProps = state => ({
    isScreenLoader: state.ui.isScreenLoader,
});

export default connect(mapStateToProps, {

})(ScreenLoader);
