import React, { lazy } from "react";
import PropTypes from "prop-types";
import { Switch, withRouter } from 'react-router-dom';
import Route from '../containers/routes/Route';
import routes, { route404 } from '../config/routes';
import LazyLoader from '../containers/elements/loaders/LazyLoader';
import ReactPixel from 'react-facebook-pixel';
import Tap from "@tapfiliate/tapfiliate-js";
import { isTapfiliate } from "../config/app";
import qs from "qs";
import {tapfiliateGetRef, tapfiliateGetRefAPI, tapfiliateSaveRef} from "../helpers/tapfiliate";
import {bundleSaleMetaPixel, isBundleSameMetaPixel} from "../config/meta";

const VerificationStatus = lazy(() => import('../containers/elements/VerificationStatus'));
const PaymentCancelPopup = lazy(() => import('../containers/elements/PaymentCancelPopup'));
const VerificationPopup = lazy(() => import('../containers/VerificationPopup'));
const VerificationInfoModal = lazy(() => import('../containers/VerificationInfoModal'));

class Wrapper extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged = () => {
        ReactPixel.pageView()
    }

    componentDidMount() {
        let {
            history,
            verificationStatus,
            resetVerificationStatus,
            hidePlayer,
            location,
        } = this.props;

        history.listen((location, action) => {
            // hiding player on redirect because browser history buttons
            // don't trigger components mount events
            hidePlayer();

            if (verificationStatus !== null) {
                resetVerificationStatus();
            }
        });

        if (isBundleSameMetaPixel) {
            ReactPixel.init(bundleSaleMetaPixel);
        }

        ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);

        if (isTapfiliate) {
            const refUrlParam = qs.parse(location.search, { ignoreQueryPrefix: true }).ref;

            if (refUrlParam) {
                tapfiliateSaveRef(refUrlParam);
            }

            try {
                let cachedRef = tapfiliateGetRefAPI();

                if (!cachedRef) {
                    cachedRef = tapfiliateGetRef();
                }

                Tap.init(process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID, {
                    integration: "stripe"
                }, () => {
                    console.info(`Tapfiliate tracking code has been initialized, ref: ${cachedRef}`);
                }, {
                    always_callback: true,
                    referral_code: refUrlParam ? refUrlParam : cachedRef,
                }, () => {
                    console.info(`Tapfiliate click is tracked, ref: ${cachedRef}`);
                });
            } catch (err) {
                console.error(err);
            }
        }

        // initial page view event
        this.onRouteChanged();
    }

    render() {
        return (
            <div>
                <LazyLoader>
                    <VerificationPopup/>
                    <VerificationStatus/>
                    <PaymentCancelPopup/>

                    <Switch>
                        {routes.map((route, index) => {
                            return <Route
                                additional={route.additional}
                                isProtected={route.isProtected}
                                isHealthCheckRequestRequired={route.isHealthCheckRequestRequired}
                                attributes={{
                                    exact: typeof route.exact === 'undefined' || route.exact,
                                }}
                                title={route.title}
                                description={route.description}
                                path={route.path}
                                redirectUnloggedTo={route.redirectUnloggedTo}
                                component={route.component}
                                key={index}
                            />
                        })}

                        <Route component={route404.component} path={route404.path} isProtected={route404.isProtected} title={route404.title} description={route404.description} />
                    </Switch>

                    <VerificationInfoModal/>
                </LazyLoader>
            </div>
        )
    }
}

Wrapper.propTypes = {
    verificationStatus: PropTypes.number,
    resetVerificationStatus: PropTypes.func.isRequired,
    hidePlayer: PropTypes.func.isRequired,
};
export default withRouter(Wrapper);
