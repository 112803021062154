import ReactPixel from 'react-facebook-pixel';
import {isBundleSameMetaPixel, bundleSaleMetaPixel} from "../config/meta";

export const META_EVENT_NAME_CHECKOUT = 'InitiateCheckout';
export const META_EVENT_NAME_PURCHASE = 'Purchase';

export const trackBundleEvent = (eventName) => {
    if (!isBundleSameMetaPixel) {
        return;
    }

    console.debug(`meta ${eventName} call is about to perform`);
    ReactPixel.trackSingle(bundleSaleMetaPixel, eventName)
}