import { isMobile, isTablet } from "../config/ui";

export const pageFeatures = 'features';

export const preload = (page) => {
    switch (page) {
        case pageFeatures:
            [
                require('../assets/images/icons/burger-white.svg'),
                require('../assets/images/icons/burger.svg'),
                require('../assets/images/logo-white-2.png'),
                require('../assets/images/logo.png'),
            ].forEach(imgSrc => {
                new Image().src = imgSrc;
            });
            break;
        default:
            break;
    }
};


export const getImage = (imagePath) => {
    const parts = imagePath.split('.');
    const ext = parts[parts.length - 1];
    const path = parts.slice(0, parts.length - 1).join('');

    let pathPostfix = '';

    if (isMobile) {
        pathPostfix = '-mobile';
    } else if (isTablet) {
        pathPostfix = '-tablet';
    }

    let image;
    try {
        image = require(`../assets/images/${path}${pathPostfix}.${ext}`);
    } catch (err) {
        image = require(`../assets/images/${path}.${ext}`);
    }

    return image;
}
