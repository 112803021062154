import React from "react";
import PropTypes from 'prop-types';
import { Route as RouteComponent } from 'react-router-dom';
import { withRouter } from "react-router-dom";

class PublicRoute extends React.PureComponent {
    componentDidMount() {
        /*let { getMe, authToken, isLoader, userData } = this.props;

        if (authToken && !isLoader && Object.keys(userData).length === 0) {
            getMe();
        }*/
    }

    render() {
        let { path, component, title, attributes } = this.props;

        return (
            <div>
                <RouteComponent path={path} title={title} component={component} {...attributes}/>
            </div>
        )
    }
}

PublicRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,

    isLogged: PropTypes.bool.isRequired,
    userData: PropTypes.object,
    getMe: PropTypes.func.isRequired,

    isLoader: PropTypes.bool.isRequired,
};

export default withRouter(PublicRoute);
