import { CHECKOUT_SUCCESS, CUSTOMER_PORTAL_SUCCESS } from '../actions/payment';
import { loadStripe } from '@stripe/stripe-js';

const redirect = async (sessionId)  => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
        sessionId,
    });

    return error;
};
const stripeMiddleware = ({ getState }) => {
    return next => action => {
        const result = next(action);

        switch (action.type) {
            case CUSTOMER_PORTAL_SUCCESS:
                //error = redirect(action.response.data.session_id);
                document.location.href = action.response.data.url;
                break;
            case CHECKOUT_SUCCESS:
                const error = redirect(action.response.data.session_id);
                console.error(error);
                break;
            default:
                break;
        }

        return result;
    };
};

export default stripeMiddleware;
