import {GET} from "../constants/apiRequestTypes";
import {
    getPackImage,
    getPackImageCompressed,
    getPackImageCompressedRect,
    getPackImageRect,
    isPackFeatured
} from "../helpers/pack";

export const SHARED_PACKS_PENDING = 'SHARED_PACKS_PENDING';
export const SHARED_PACKS_SUCCESS = 'SHARED_PACKS_SUCCESS';
export const SHARED_PACKS_ERROR   = 'SHARED_PACKS_ERROR';

export const PACK_IMAGE_LOAD_SUCCESS      = 'PACK_IMAGE_LOAD_SUCCESS';
export const PACK_IMAGE_RECT_LOAD_SUCCESS = 'PACK_IMAGE_RECT_LOAD_SUCCESS';
export const PACK_IMAGE_RECT_TOTAL_COUNT  = 'PACK_IMAGE_RECT_TOTAL_COUNT';

export const getSharedPacks = (name, styles, limit, page, isPreloadFeaturedImages) => dispatch => {
    return dispatch({
        type: GET,
        onPending: SHARED_PACKS_PENDING,
        onSuccess: (response) => {
            if (response.data && response.data.results && response.data.results.length > 0) {
                let totalFeaturedImages = 0;

                for (let i = 0; i < response.data.results.length; i++) {
                    const pack = response.data.results[i];
                    const compressedImageUrl = getPackImageCompressed(pack);

                    if (!compressedImageUrl) {
                        continue;
                    }

                    let image = new Image();
                    image.src = compressedImageUrl;
                    image.onload = () => {
                        return dispatch({
                            type: PACK_IMAGE_LOAD_SUCCESS,
                            image: compressedImageUrl,
                            id: pack.id
                        });
                    }
                    image.onerror = () => {
                        return dispatch({
                            type: PACK_IMAGE_LOAD_SUCCESS,
                            image: getPackImage(pack),
                            id: pack.id
                        });
                    }

                    if (isPreloadFeaturedImages && isPackFeatured(pack)) {
                        const compressedImageUrlRect = getPackImageCompressedRect(pack);

                        totalFeaturedImages++;

                        let imageRect = new Image();
                        imageRect.src = compressedImageUrlRect;
                        imageRect.onload = () => {
                            return dispatch({
                                type: PACK_IMAGE_RECT_LOAD_SUCCESS,
                                imageRect: compressedImageUrlRect,
                                id: pack.id
                            });
                        }
                        imageRect.onerror = () => {
                            return dispatch({
                                type: PACK_IMAGE_RECT_LOAD_SUCCESS,
                                imageRect: getPackImageRect(pack),
                                id: pack.id
                            });
                        }
                    }
                }

                dispatch({
                    type: PACK_IMAGE_RECT_TOTAL_COUNT,
                    count: totalFeaturedImages,
                });
            }
            return dispatch({
                type: SHARED_PACKS_SUCCESS,
                response
            });
        },
        onFailure: SHARED_PACKS_ERROR,
        promise: (api) => {
            return api.getSharedPacks(name, styles, limit, page);
        },
    })
};

export const DOWNLOAD_SOUND_PENDING = 'DOWNLOAD_SOUND_PENDING';
export const DOWNLOAD_SOUND_SUCCESS = 'DOWNLOAD_SOUND_SUCCESS';
export const DOWNLOAD_SOUND_ERROR   = 'DOWNLOAD_SOUND_ERROR';

export const getSound = (soundId) => dispatch => {
    return dispatch({
        type: GET,
        onPending: DOWNLOAD_SOUND_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: DOWNLOAD_SOUND_SUCCESS,
                response
            });
        },
        onFailure: DOWNLOAD_SOUND_ERROR,
        promise: (api) => {
            return api.downloadSound(soundId);
        },
    })
};


export const GET_STYLES_PENDING = 'GET_STYLES_PENDING';
export const GET_STYLES_SUCCESS = 'GET_STYLES_SUCCESS';
export const GET_STYLES_ERROR   = 'GET_STYLES_ERROR';

export const getStyles = () => dispatch => {
    return dispatch({
        type: GET,
        onPending: GET_STYLES_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: GET_STYLES_SUCCESS,
                response
            });
        },
        onFailure: GET_STYLES_ERROR,
        promise: (api) => {
            return api.getStyles();
        },
    })
};

export const PACK_DATA_PENDING = 'PACK_DATA_PENDING';
export const PACK_DATA_SUCCESS = 'PACK_DATA_SUCCESS';
export const PACK_DATA_ERROR   = 'PACK_DATA_ERROR';

export const getPack = (packId) => dispatch => {
    return dispatch({
        type: GET,
        packId: packId,
        onPending: PACK_DATA_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: PACK_DATA_SUCCESS,
                response
            });
        },
        onFailure: PACK_DATA_ERROR,
        promise: (api) => {
            return api.getPack(packId);
        },
    })
};

