import { connect } from 'react-redux';
import LazyLoader from '../../../components/elements/loaders/LazyLoader';

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {

})(LazyLoader);
