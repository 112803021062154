import { connect } from 'react-redux';
import InternalError from '../components/InternalError';
import { registerPageView } from '../actions/analytics';

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, {
    registerPageView,
})(InternalError);
