import * as sliderActions from '../actions/slider';
import { slides, startSlideIndex } from '../config/slider';

const initialState = {
    slideIndex: startSlideIndex,
    slidesCount: slides.length,
    isSliderRunning: false,
};

function slider(state = initialState, action) {
    switch (action.type) {
        case sliderActions.STOP_SLIDER:
            return {...state, isSliderRunning: false};
        case sliderActions.START_SLIDER:
            return {...state, isSliderRunning: true};
        case sliderActions.SLIDE_PREV:
            let prevIndex = state.slideIndex - 1;
            if (prevIndex < 0) {
                prevIndex = state.slidesCount - 1;
            }
            return {...state, slideIndex: prevIndex};
        case sliderActions.SLIDE_NEXT:
            let nextIndex = state.slideIndex + 1;
            if (nextIndex >= state.slidesCount) {
                nextIndex = 0;
            }
            return {...state, slideIndex: nextIndex};
        default:
            return state;
    }
}

export default slider;
