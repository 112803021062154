import * as packsActions from "../actions/pack";

const initialState = {
    isPacksPending: false,
    isPacksFeaturedImagesPending: true,
    totalPackFeaturedImages: 0,
    totalPackFeaturedImagesLoaded: 0,
    isStalesPending: false,
    sharedPacks: [],
    styles: [],
    page: 1,
    userPacksPending: {},
    userPacksData: {},
    packImages: {},
    packImagesRect: {},
};

function packs(state = initialState, action) {
    switch (action.type) {
        case packsActions.PACK_IMAGE_RECT_TOTAL_COUNT:
            return {
                ...state,
                totalPackFeaturedImages: action.count,
            };
        case packsActions.PACK_IMAGE_RECT_LOAD_SUCCESS:
            let packImagesRect = {};
            packImagesRect[action.id] = action.imageRect;

            const totalPackFeaturedImagesLoaded = state.totalPackFeaturedImagesLoaded + 1;

            return {
                ...state,
                packImagesRect: {...state.packImagesRect, ...packImagesRect},
                totalPackFeaturedImagesLoaded: totalPackFeaturedImagesLoaded,
                isPacksFeaturedImagesPending: totalPackFeaturedImagesLoaded < state.totalPackFeaturedImages,
            };
        case packsActions.PACK_IMAGE_LOAD_SUCCESS:
            let packImages = {};
            packImages[action.id] = action.image;
            return {
                ...state,
                packImages: {...state.packImages, ...packImages},
            };
        case packsActions.PACK_DATA_SUCCESS:
            const packData = action.response.data.results;
            let userPacksSuccess = {};
            let userPacksData = {};
            userPacksSuccess[packData.id] = false;
            userPacksData[packData.id] = packData;
            return {
                ...state,
                userPacksPending: {...state.userPacksPending, ...userPacksSuccess},
                userPacksData: {...state.userPacksData, ...userPacksData},
            };
        case packsActions.PACK_DATA_PENDING:
            let userPacksPending = {};
            userPacksPending[action.packId] = true;
            return {...state, userPacksPending: {...state.userPacksPending, ...userPacksPending}};
        case packsActions.GET_STYLES_ERROR:
            return {...state, isStalesPending: false};
        case packsActions.GET_STYLES_SUCCESS:
            return {...state, isStalesPending: false, styles: action.response.data.results};
        case packsActions.GET_STYLES_PENDING:
            return {...state, isStalesPending: true};
        case packsActions.SHARED_PACKS_ERROR:
            return {...state, isPacksPending: false, sharedPacks: initialState.sharedPacks};
        case packsActions.SHARED_PACKS_SUCCESS:
            return {...state, isPacksPending: false, sharedPacks: action.response.data.results, page: action.response.data.page};
        case packsActions.SHARED_PACKS_PENDING:
            return {...state, isPacksPending: true};
        default:
            return state;
    }
}


export default packs;
