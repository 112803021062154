import * as uiActions from '../actions/ui';
import * as userActions from '../actions/user';
import * as paymentActions from '../actions/payment';
import * as packActions from '../actions/pack';
import localStorageObject from '../storages/localStorage';
import * as storageKeys from '../constants/storageKeys';
import {getUserPackStorageKey} from "../helpers/pack";
import { userPackExpiresSeconds } from "../config/packs";
import { defaultRoute } from '../config/routes';
import {tapfiliateSaveRefAPI} from "../helpers/tapfiliate";

const storageClass = localStorageObject;

const localStorageMiddleware = ({ getState }) => {
    return next => action => {
        switch (action.type) {
            case packActions.PACK_DATA_SUCCESS:
                const packData = {
                    payload: action.response.data.results,
                    expires: + new Date() + (userPackExpiresSeconds * 1000),
                }
                storageClass.set(getUserPackStorageKey(action.response.data.results.id), packData);
                break;
            case paymentActions.PORTAL_CANCEL_SHOWN:
                storageClass.remove(storageKeys.PORTAL_PAGE);
                break;
            case paymentActions.CUSTOMER_PORTAL_SUCCESS:
                storageClass.set(storageKeys.PORTAL_PAGE, document.location.pathname);
                break;
            case paymentActions.BUNDLE_CHECKOUT_AFTER_REGISTRATION:
                storageClass.set(storageKeys.BUNDLE_2022_STRIPE_PRICE_ID, action.stripePriceId);
                break;
            case paymentActions.BUNDLE_CHECKOUT_AFTER_REGISTRATION_COMPLETE:
                storageClass.remove(storageKeys.BUNDLE_2022_STRIPE_PRICE_ID);
                break;
            case paymentActions.PAYMENT_CANCEL_SHOWN:
                storageClass.remove(storageKeys.CHECKOUT_PAGE);
                break;
            case paymentActions.CHECKOUT_SUCCESS:
                storageClass.set(storageKeys.CHECKOUT_PAGE, document.location.pathname);
                break;
            case userActions.LOGOUT_SUCCESS:
            case userActions.UNAUTHORIZED_ERROR:
                storageClass.remove(storageKeys.EMAIL_TO_VERIFICATE);
                storageClass.remove(storageKeys.ACCESS_TOKEN);
                storageClass.remove(storageKeys.USER_DATA);

                document.location.href = action.type === userActions.LOGOUT_SUCCESS ? defaultRoute : '/login';
                break;
            case uiActions.CLOSE_COOKIE_BANNER:
                storageClass.set(storageKeys.COOKIE_BANNER_CLOSED, true);
                break;
            case userActions.REGISTER_SUCCESS:
                storageClass.set(storageKeys.EMAIL_TO_VERIFICATE, action.response.data.email);
                storageClass.set(storageKeys.ACCESS_TOKEN, action.response.data.auth.access_token);
                storageClass.set(storageKeys.USER_DATA, action.response.data);
                break;
            case userActions.VERIFICATION_STATUS:
                if (action.token && action.token !== '') {
                    storageClass.set(storageKeys.ACCESS_TOKEN, action.token);
                }
                break;
            case userActions.FORGOT_SUCCESS:
            case userActions.PASSWORD_RESET_SUCCESS:

                storageClass.remove(storageKeys.EMAIL_TO_VERIFICATE);
                storageClass.remove(storageKeys.ACCESS_TOKEN);
                storageClass.remove(storageKeys.USER_DATA);
                break;
            case userActions.LOGIN_SUCCESS:
                storageClass.set(storageKeys.ACCESS_TOKEN, action.response.data.access_token);
                //storageClass.set(storageKeys.USER_DATA, action.response.data);
                break;
            case userActions.GET_ME_SUCCESS:
                //storageClass.set(storageKeys.ACCESS_TOKEN, action.response.data.data.access_token);
                storageClass.set(storageKeys.USER_DATA, action.response.data);

                if (typeof action.response.data.affiliate_ref_id !== "undefined" && action.response.data.affiliate_ref_id != "") {
                    tapfiliateSaveRefAPI(action.response.data.affiliate_ref_id);
                }
                break;
            default:
                break;
        }

        return next(action);
    };
};

export default localStorageMiddleware;
