export const PLAYER_PLAY = 'PLAYER_PLAY';
export const play = (url, title, tags) => dispatch => dispatch({
    type: PLAYER_PLAY,
    url,
    title,
    tags,
});

export const SHOW_PLAYER = 'SHOW_PLAYER';
export const showPlayer = (pack, title, image) => dispatch => dispatch({
    type: SHOW_PLAYER,
    pack,
    title,
    image,
});
export const HIDE_PLAYER = 'HIDE_PLAYER';
export const hidePlayer = () => dispatch => dispatch({
    type: HIDE_PLAYER,
});

export const PLAYER_STOP = 'PLAYER_STOP';
export const stop = () => dispatch => dispatch({
    type: PLAYER_STOP,
});

export const PLAYER_PAUSE = 'PLAYER_PAUSE';
export const pause = () => dispatch => dispatch({
    type: PLAYER_PAUSE,
});
export const PLAYER_CONTINUE = 'PLAYER_CONTINUE';
export const continuePlay = () => dispatch => dispatch({
    type: PLAYER_CONTINUE,
});

export const PLAYER_NEXT = 'PLAYER_NEXT';
export const next = () => dispatch => dispatch({
    type: PLAYER_NEXT,
});
export const PLAYER_PREV = 'PLAYER_PREV';
export const prev = () => dispatch => dispatch({
    type: PLAYER_PREV,
});

export const PLAYER_VOLUME = 'PLAYER_VOLUME';
export const volume = (volume) => dispatch => dispatch({
    type: PLAYER_VOLUME,
    volume,
});

