import React from "react";
import CenteredContentWrapper from './elements/CenteredContentWrapper';
import BlankLink from './elements/BlankLink';
import { helpCenterLink } from '../config/app';
import {titles} from "../config/routes";

export default class InternalError extends React.PureComponent {
    componentDidMount() {
        let { registerPageView } = this.props;

        registerPageView(titles.pageNotFound, 'internal-error');
    }

    render() {
        return (
            <div className={'page page-404 internal-error-page'}>
                <CenteredContentWrapper>
                    <div className={'container'}>
                        <div className={'left-block'}>
                            <div className={'title'}>
                                Sorry, server
                                isn't responding right now
                            </div>
                            <div className={'sub-title'}>
                                We are working on it
                            </div>

                            <div className={'help-container'}>
                                Need help? <BlankLink className={'link'} link={helpCenterLink} text={'Visit our Help Center'}/>
                            </div>
                        </div>

                        <div className={'mobile-element mobile-image'}>

                        </div>
                    </div>
                </CenteredContentWrapper>
            </div>
        );
    }
}
