import React, { Suspense } from "react";
import PropTypes from 'prop-types';
import ScreenLoader from './ScreenLoader';

export default class LazyLoader extends React.PureComponent {
    render() {
        const { children } = this.props;

        return (
            <Suspense fallback={<ScreenLoader isScreenLoader={true}/>}>{children}</Suspense>
        );
    }
}

LazyLoader.propTypes = {
    children: PropTypes.node,
}
