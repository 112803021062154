import { withRouter, Redirect } from 'react-router-dom';
import React from "react";
import PropTypes from 'prop-types';
import ProtectedRoute from '../../containers/routes/ProtectedRoute';
import PublicRoute from '../../containers/routes/PublicRoute';
import qs from 'qs';
import {defaultRoute} from '../../config/routes';
import InternalError from '../../containers/InternalError';
import ScreenLoader from '../../containers/elements/loaders/ScreenLoader';
import Helmet from 'react-helmet';
import { formatTitle } from "../../helpers/utils";
import { freeTrialLink } from "../../config/app";

class Route extends React.PureComponent {
    state = {
        isRedirected: false,
    };

    componentDidMount() {
        const {
            healthCheck,
            isHealthCheckRequestRequired,
        } = this.props;

        if (isHealthCheckRequestRequired) {
            return healthCheck(this.init);
        }

        this.init();
    }

    init = () => {
        const {
            setVerificationStatus,
            location,
            getMe,
            authToken,
            userData,
            history,
            isNeedUpdate,
            isNeedPlansRequest,
            getPlans,
        } = this.props;

        if (isNeedPlansRequest) {
            getPlans();
        }

        const verifiedStatus = parseInt(qs.parse(location.search, { ignoreQueryPrefix: true }).verified);

        if (!isNaN(verifiedStatus)) {
            const getAccessToken = qs.parse(location.search, { ignoreQueryPrefix: true }).token;

            setVerificationStatus(getAccessToken, verifiedStatus, getAccessToken !== '');

            if ((!authToken || Object.keys(userData).length === 0) && getAccessToken !== '') {
                getMe('/dashboard', history);
            }
        } else {
            if (authToken !== '' && (Object.keys(userData).length === 0 || isNeedUpdate)) {
                getMe();
            }
        }

        window.scrollTo(0, 0);
    };
    renderRoute = () => {
        let {
            path,
            component,
            attributes,
            isProtected,
            authToken,
            location,
            redirectUnloggedTo,
            isInternalError,
            isScreenLoader,
        } = this.props;

        if (location.pathname === '/') {
            return <Redirect to={authToken ? '/dashboard' : defaultRoute}/>
        }
        if (isInternalError) {
            return <InternalError/>
        }
        if (isScreenLoader) {
            return <ScreenLoader/>;
        }
        if (isProtected) {
            return <ProtectedRoute path={path} component={component} redirectUnloggedTo={redirectUnloggedTo} attributes={attributes}/>
        }

        return (
            <PublicRoute path={path} component={component} attributes={attributes}/>
        )
    };

    renderHeaderTags = () => {
        let { title, description, path, monthPlan } = this.props;

        if (path === freeTrialLink) {
            description = `Start your ${monthPlan.trial_duration} day free trial to Capsule and access the entire Capsule lineup`;
        }
        return (
            <Helmet>
                <title>{formatTitle(title)}</title>]
                <meta name={"description"} content={description} />
            </Helmet>
        );
    };

    render() {
        return (
            <div className="application">
                {this.renderHeaderTags()}
                {this.renderRoute()}
            </div>
        )

    }
}

export default withRouter(Route);

Route.propTypes = {
    path: PropTypes.string.isRequired,
    authToken: PropTypes.string,
    component: PropTypes.object,
    monthPlan: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,
    setVerificationStatus: PropTypes.func.isRequired,
    redirectUnloggedTo: PropTypes.string,
    isNeedUpdate: PropTypes.bool.isRequired,
    isScreenLoader: PropTypes.bool.isRequired,
    isInternalError: PropTypes.bool.isRequired,
    isHealthCheckRequestRequired: PropTypes.bool,
    isNeedPlansRequest: PropTypes.bool.isRequired,
    isLogged: PropTypes.bool.isRequired,
    getPlans: PropTypes.func.isRequired,
    getMe: PropTypes.func.isRequired,
    healthCheck: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};
