import { POST, GET } from '../constants/apiRequestTypes';
import { DELAYED_ACTION } from "../constants/actions";
import { updateUserDelay} from "../config/app";
import {getPack} from "./pack";

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_PENDING_STOP = 'REGISTER_PENDING_STOP';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR   = 'REGISTER_ERROR';

export const registerPending = ()  => dispatch => dispatch({
    type: REGISTER_PENDING,
});
export const registerStopPending = ()  => dispatch => dispatch({
    type: REGISTER_PENDING_STOP,
});
export const registerSend = (username, email, password, confirmPassword, voucherCode, affiliateRefId) => dispatch => {
    return dispatch({
        type: POST,
        onPending: REGISTER_PENDING,
        onSuccess: (response) => {
            return dispatch(registerSuccess(response));
        },
        onFailure: REGISTER_ERROR,
        promise: (api) => {
            return api.register(username, email, password, confirmPassword, voucherCode, affiliateRefId);
        },
    })
};
export const registerSuccess = response => dispatch => dispatch({
    type: REGISTER_SUCCESS,
    response
});

export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR   = 'CHANGE_PASSWORD_ERROR';

export const changePassword = (currentPassword, password, confirmPassword) => dispatch => {
    return dispatch({
        type: POST,
        onPending: CHANGE_PASSWORD_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
                response
            });
        },
        onFailure: CHANGE_PASSWORD_ERROR,
        promise: (api) => {
            return api.changePassword(currentPassword, password, confirmPassword);
        },
    })
};

export const RESEND_VERIFICATION_PENDING = 'RESEND_VERIFICATION_PENDING';
export const RESEND_VERIFICATION_SUCCESS = 'RESEND_VERIFICATION_SUCCESS';
export const RESEND_VERIFICATION_ERROR   = 'RESEND_VERIFICATION_ERROR';
export const RESEND_VERIFICATION_RESET   = 'RESEND_VERIFICATION_RESET';

export const resetEmailVerification = () => ({
    type: RESEND_VERIFICATION_RESET
});
export const resendEmailVerification = (email) => ({
    type: POST,
    onPending: RESEND_VERIFICATION_PENDING,
    onSuccess: RESEND_VERIFICATION_SUCCESS,
    onFailure: RESEND_VERIFICATION_ERROR,
    promise: (api) => {
        return api.resendEmailVerification(email);
    },
});

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const GET_ME_PENDING = 'GET_ME_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const LOGIN_ERROR   = 'LOGIN_ERROR';

export const getMe = (redirectTo, history) => (dispatch) => {
    return dispatch({
        type: GET,
        onPending: GET_ME_PENDING,
        onSuccess: (response) => {
            dispatch({
                type: GET_ME_SUCCESS,
                response,
                redirectTo,
                history,
            });
        },
        onFailure: LOGIN_ERROR,
        promise: (api) => {
            return api.me();
        },
    });
};

export const GET_ME_SILENT_PENDING = 'GET_ME_SILENT_PENDING';
export const GET_ME_SILENT_SUCCESS = 'GET_ME_SILENT_SUCCESS';

export const getMeSilent = (redirectTo, history) => (dispatch) => {
    return dispatch({
        type: GET,
        onPending: GET_ME_SILENT_PENDING,
        onSuccess: (response) => {
            if (response.data.purchased_packs && response.data.purchased_packs.length > 0) {
                for(let i = 0; i < response.data.purchased_packs.length; i++) {
                    dispatch(getPack(response.data.purchased_packs[i].id));
                }
            }

            dispatch({
                type: GET_ME_SILENT_SUCCESS,
                response,
                redirectTo,
                history,
            });
        },
        onFailure: LOGIN_ERROR,
        promise: (api) => {
            return api.me();
        },
    });
};
export const loginSend = (email, password) => dispatch => {
    return dispatch({
        type: POST,
        onPending: LOGIN_PENDING,
        onSuccess: (response) => {
            dispatch({
                type: LOGIN_SUCCESS,
                response,
            });

            return dispatch(getMe());
        },
        onFailure: LOGIN_ERROR,
        promise: (api) => {
            return api.login(email, password);
        },
    })
};

export const FORGOT_PENDING = 'FORGOT_PENDING';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_ERROR   = 'FORGOT_ERROR';

export const forgotSend = (email) => dispatch => {
    return dispatch({
        type: POST,
        onPending: FORGOT_PENDING,
        onSuccess: FORGOT_SUCCESS,
        onFailure: FORGOT_ERROR,
        promise: (api) => {
            return api.forgot(email);
        },
    })
};

export const PASSWORD_RESET_PENDING = 'PASSWORD_RESET_PENDING';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR   = 'PASSWORD_RESET_ERROR';

export const passwordResetSend = (email, password, passwordConfirmation, token) => dispatch => {
    return dispatch({
        type: POST,
        onPending: PASSWORD_RESET_PENDING,
        onSuccess: (response) => {
            dispatch({
                type: PASSWORD_RESET_SUCCESS,
                response,
            });

            return dispatch(getMe());
        },
        onFailure: PASSWORD_RESET_ERROR,
        promise: (api) => {
            return api.passwordReset(email, password, passwordConfirmation, token);
        },
    })
};

export const UPDATE_ME_PENDING = 'UPDATE_ME_PENDING';
export const UPDATE_ME_SUCCESS = 'UPDATE_ME_SUCCESS';
export const UPDATE_ME_ERROR   = 'UPDATE_ME_ERROR';

export const updateMe = (data) => dispatch => {
    return dispatch({
        type: POST,
        onPending: UPDATE_ME_PENDING,
        onSuccess: UPDATE_ME_SUCCESS,
        onFailure: UPDATE_ME_ERROR,
        promise: (api) => {
            return api.updateMe(data);
        },
    })
};

export const RESET_VERIFICATION_STATUS = 'RESET_VERIFICATION_STATUS';

export const resetVerificationStatus = () => dispatch => dispatch({
    type: RESET_VERIFICATION_STATUS,
});

export const VERIFICATION_STATUS = 'VERIFICATION_STATUS';

export const setVerificationStatus = (token, status, isLoader) => dispatch => dispatch({
    type: VERIFICATION_STATUS,
    token,
    status,
    isLoader,
});


export const LOGOUT_PENDING = 'LOGOUT_PENDING';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR   = 'LOGOUT_ERROR';

export const logout = () => dispatch => {
    return dispatch({
        type: POST,
        onPending: LOGOUT_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: LOGOUT_SUCCESS,
                response,
            });
        },
        onFailure: LOGOUT_ERROR,
        promise: (api) => {
            return api.logout();
        },
    })
};

export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
export const unauthorizedError = () => dispatch => dispatch({
    type: UNAUTHORIZED_ERROR,
});

export const NEED_UPDATE_INITIALIZE = 'NEED_UPDATE_INITIALIZE';
export const NEED_UPDATE_CALL = 'NEED_UPDATE_CALL';
export const needUpdateDelayed = () => dispatch => dispatch({
    type: DELAYED_ACTION,
    first: NEED_UPDATE_INITIALIZE,
    second: NEED_UPDATE_CALL,
    delay: updateUserDelay,
});
