import * as apiRequestTypes from '../constants/apiRequestTypes';
import { internalServerError } from '../actions/ui';
import { unauthorizedError, LOGIN_ERROR } from '../actions/user';

export default function apiMiddleware(httpAPIClient) {
    return ({dispatch, getState}) => {

        return next => action => {
            if (typeof action === 'function') {
                return action(dispatch, getState);
            }

            const { promise, type, onPending, onSuccess, onFailure, ...rest } = action;

            if (Object.keys(apiRequestTypes).indexOf(type) === -1 || !promise) {
                // not an API request
                return next(action);
            }

            if (typeof onPending !== 'undefined') {
                next({...rest, type: onPending});
            }

            if (process.env.REACT_APP_STAGE === 'test') {
                return next(action);
            }

            const actionPromise = promise(httpAPIClient);

            return actionPromise
                .then((result) => {
                    let response = httpAPIClient.parseRespone(result);

                    if (!response.isSuccess) {
                        if (typeof onFailure !== 'undefined') {
                            return next({...rest, response, type: onFailure });
                        }

                        return false;
                    }

                    let successDispatch = false;

                    if (typeof onSuccess !== 'undefined') {
                        if (typeof onSuccess === 'function') {
                            successDispatch = onSuccess(response);
                        } else {
                            successDispatch = next({...rest, response, type: onSuccess });
                        }
                    }

                    return successDispatch;
                })
                .catch((error, a) => {
                    console.error('api error', error);

                    if (typeof error.response !== 'undefined' && error.response.status === 401 && onFailure !== LOGIN_ERROR) {
                        return dispatch(unauthorizedError());
                    }
                    if (typeof onFailure !== 'undefined' ) {
                        let errorText = 'Internal error';
                        if (typeof error.response !== 'undefined') {
                            if (typeof error.response.data !== 'undefined') {
                                let data = typeof error.response.data.data !== 'undefined' ? error.response.data.data : error.response.data;

                                if (typeof data.errors !== 'undefined') {
                                    errorText = data.errors
                                }
                                if (typeof data.error !== 'undefined') {
                                    errorText = data.error
                                }
                            }
                        } else {
                            // internal server error or server is not responding
                            dispatch(internalServerError(error));
                        }

                        if (typeof onFailure === 'function') {
                            return onFailure({error: errorText});
                        }

                        return next({...rest, errors: errorText, error: errorText, type: onFailure });
                    }
                });
        }
    }
}
