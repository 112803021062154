import { connect } from 'react-redux';
import Wrapper from '../components/Wrapper';
import { resetVerificationStatus } from '../actions/user';
import { hidePlayer } from '../actions/player';

const mapStateToProps = state => ({
    verificationStatus: state.ui.verificationStatus,
});

export default connect(mapStateToProps, {
    resetVerificationStatus,
    hidePlayer,
})(Wrapper);
