const localStorageObject = {
    remove: (key)  => {
        return window.localStorage.removeItem(key);
    },
    set: (key, value)  => {
        return window.localStorage.setItem(key, JSON.stringify(value));
    },
    get: (key) => {
        let value = null;

        try {
            value = JSON.parse(window.localStorage.getItem(key));
        } catch (e) {
            value = null;
        }

        return value;
    },
    is: (key) => {
        return !!localStorageObject.get(key);
    },
    isNot: (key) => {
        return !localStorageObject.get(key);
    },
};

export default localStorageObject;
