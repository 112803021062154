import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import GTM from '../libraries/gtm';
import Wrapper from './Wrapper';
import '../styles';

GTM.init();

const Root = ({ store }) => (
    <Provider store={store}>
        <Wrapper/>
    </Provider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired,
};

export default Root;

