import ui from './ui';
import user from './user';
import slider from './slider';
import packs from './packs';
import plans from './plans';
import system from './system';
import voucher from './voucher';

// for now we can load all reducers initially
// but in the future we should make it dynamically
// if the logic will be resource greedy
const initialReducers = {
    ui,
    slider,
    user,
    packs,
    plans,
    system,
    voucher,
};

export default initialReducers;
