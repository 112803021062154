import moment from 'moment';

export const redirect = (url) => {
    try {
        window.history.pushState(null, null, url)
    } catch (e) {
        document.location.href = url;
    }
};

export const formatTitle = (title) => {
    return `${title} | Capsule`;
};

export const isLoginLocation = () => {
    return /^\/login.{0,}$/.test(document.location.pathname);
};

export const formatBundleSaleDate = (dateObject) => {
    const formatLocale = "en-US";
    const formatOptions = {
        day: "numeric",
        month: "long",
        hour: "numeric",
        timeZone: "America/Los_Angeles",
        timeZoneName: "short"
    };
    return Intl.DateTimeFormat(formatLocale, formatOptions)
        .formatToParts(dateObject)
        .map(({ type, value}) => {
            switch (type) {
                case "day":
                    return moment.localeData(formatLocale).ordinal(value);
                default:
                    return value;
            }
        }).join("");
};