import React from "react";
import PropTypes from 'prop-types';

export default class ScreenLoader extends React.PureComponent {
    render() {
        let { isScreenLoader } = this.props;

        if (!isScreenLoader) {
            return false;
        }

        return (
            <div className={'loader screen-loader'}>
                <img
                    className={'loader-img'}
                    src={require('../../../assets/images/logo.svg')}
                    alt={'Soundslates'}
                />
            </div>
        );
    }
}

ScreenLoader.propTypes = {
    isScreenLoader: PropTypes.bool,
    //className: PropTypes.string,
};
