import { isGTMEnabled } from '../config/app';

const gtm = {
    init: () => {
        if (!isGTMEnabled) {
            return false;
        }

        let envPath = '';
        if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV && process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV !== '') {
            envPath = `&gtm_preview=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ENV}&gtm_auth=${process.env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH}&gtm_cookies_win=x`;
        }
        // google tag manager script
        const script = document.createElement("script");
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl+'${envPath}';f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}');`;
        document.head.prepend(script);
    },
    dataLayer: (data) => {
        if (!isGTMEnabled) {
            return false;
        }

        if (window.dataLayer) {
            try {
                window.dataLayer.push(data);
            } catch (e) {
                console.error("dataLayer error", e);
            }
        }
    }
};

export default gtm;
