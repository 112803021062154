import * as plansActions from '../actions/plans';

const initialState = {
    isPlansRequested: false,
    plans: [],
};

function plans(state = initialState, action) {
    switch (action.type) {
        case plansActions.GET_PLANS_PENDING:
            return {...state, isPlansRequested: true};
        case plansActions.GET_PLANS_SUCCESS:
            return {...state, plans: action.response.data};
        case plansActions.GET_PLANS_ERROR:
            // TODO show error may be
            return state;
        default:
            return state;
    }
}

export default plans;
