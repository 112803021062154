import { verificationPopupFocusAnimationTime } from "../config/ui";
import { DELAYED_ACTION } from "../constants/actions";

export const TOGGLE_VIDEO_SOUND = 'TOGGLE_VIDEO_SOUND';

export const SHOW_MOBILE_MENU = 'SHOW_MOBILE_MENU';
export const HIDE_MOBILE_MENU = 'HIDE_MOBILE_MENU';

export const CLOSE_COOKIE_BANNER = 'CLOSE_COOKIE_BANNER';

export const SHOW_HEADER = 'SHOW_HEADER';
export const HIDE_HEADER = 'HIDE_HEADER';

export const HIDE_SIGN_UP_SUCCESS_MESSAGE = 'HIDE_SIGN_UP_SUCCESS_MESSAGE';

export const toggleVideoSound = () => dispatch => dispatch({
    type: TOGGLE_VIDEO_SOUND,
});

export const showMobileMenu = () => dispatch => dispatch({
    type: SHOW_MOBILE_MENU,
});
export const hideMobileMenu = () => dispatch => dispatch({
    type: HIDE_MOBILE_MENU,
});

export const closeCookieBanner = () => dispatch => dispatch({
    type: CLOSE_COOKIE_BANNER,
});

export const showHeader = () => dispatch => dispatch({
    type: SHOW_HEADER,
});
export const hideHeader = () => dispatch => dispatch({
    type: HIDE_HEADER,
});

export const hideSignUpSuccessMessage = () => dispatch => dispatch({
    type: HIDE_SIGN_UP_SUCCESS_MESSAGE,
});

export const CLEAR_SIGN_ERRORS = 'CLEAR_SIGN_ERRORS';
export const clearSignErrors = () => dispatch => dispatch({
    type: CLEAR_SIGN_ERRORS,
});

export const CLEAR_CHANGE_PASSWORD_ERRORS = 'CLEAR_CHANGE_PASSWORD_ERRORS';
export const clearChangePasswordErrors = () => dispatch => dispatch({
    type: CLEAR_CHANGE_PASSWORD_ERRORS,
});

export const FOCUS_VERIFICATION_POPUP = 'FOCUS_VERIFICATION_POPUP';
export const UNFOCUS_VERIFICATION_POPUP = 'UNFOCUS_VERIFICATION_POPUP';
export const focusVerificationPopup = () => dispatch => dispatch({
    type: DELAYED_ACTION,
    first: FOCUS_VERIFICATION_POPUP,
    second: UNFOCUS_VERIFICATION_POPUP,
    delay: verificationPopupFocusAnimationTime,
});

export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const internalServerError = (error) => dispatch => dispatch({
    type: INTERNAL_SERVER_ERROR,
    error,
});

export const PLAY_HERO_VIDEO = 'PLAY_HERO_VIDEO';
export const playHeroVideo = () => dispatch => dispatch({
    type: PLAY_HERO_VIDEO,
});

export const PLAY_VIDEO = 'PLAY_VIDEO';
export const playVideo = (videoType, link) => dispatch => dispatch({
    type: PLAY_VIDEO,
    videoType,
    link,
});

export const STOP_VIDEO = 'STOP_VIDEO';
export const stopVideo = () => dispatch => dispatch({
    type: STOP_VIDEO,
});

export const VIDEO_INTRO_COMPLETE = 'VIDEO_INTRO_COMPLETE';
export const videoIntroComplete = () => dispatch => dispatch({
    type: VIDEO_INTRO_COMPLETE,
});

export const SHOW_VERIFICATION_MODAL = 'SHOW_VERIFICATION_MODAL';
export const showVerificationModal = () => dispatch => dispatch({
    type: SHOW_VERIFICATION_MODAL,
});

export const HIDE_VERIFICATION_MODAL = 'HIDE_VERIFICATION_MODAL';
export const hideVerificationModal = () => dispatch => dispatch({
    type: HIDE_VERIFICATION_MODAL,
});
