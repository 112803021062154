export const colors = [
    'white',
    'purple',
];

export const iconColors = colors;

export const cookieBannerFadeSpeed = 1;

export const verificationPopupFocusAnimationTime = 1;

export const defaultSplashPageMessage = 'Coming soon';

const windowWidth = window.innerWidth;

// the same values as in the styles/config.scss
export const isMobile = windowWidth <= 420;
export const isTablet = windowWidth <= 768;
