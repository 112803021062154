import httpRequest from '../libraries/httpRequest';
import * as apiRequestTypes from '../constants/apiRequestTypes';
import * as apiEndpoints from '../constants/apiEndpoints';
import storage from '../storages/storage';
import * as storageKeys from '../constants/storageKeys';
import {STRIPE_CHECKOUT_BUNDLE_CREATE} from "../constants/apiEndpoints";

export default class httpAPIClient {
    http;

    url;

    constructor() {
        this.http = new httpRequest();
        this.url = process.env.REACT_APP_API_URL;

        if (this.url.slice(-1) !== '/') {
            this.url = `${this.url}/`;
        }
    }

    healthcheck() {
        return this.request(apiRequestTypes.GET, apiEndpoints.HEALTHCHECK);
    }
    register(name, email, password, password_confirmation, voucher_code, affiliate_ref_id) {
        var params = {
            name,
            email,
            password,
            password_confirmation,
            voucher_code,
        };

        if (affiliate_ref_id) {
            params.affiliate_ref_id = affiliate_ref_id;
        }

        return this.request(apiRequestTypes.POST, apiEndpoints.REGISTER, params);
    }
    changePassword(current_password, password, password_confirmation) {
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.CHANGE_PASSWORD, {
            current_password,
            password,
            password_confirmation
        });
    }

    updateMe(data) {
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.ME, data);
    }

    logout() {
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.LOGOUT, {});
    }

    resendEmailVerification(email) {
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.RESEND_EMAIL_VERIFICATION, {
            email,
        });
    }

    login(email, password) {
        return this.request(apiRequestTypes.POST, apiEndpoints.LOGIN, {
            email,
            password,
        });
    }

    me() {
        return this.requestToken(apiRequestTypes.GET, apiEndpoints.ME);
    }

    forgot(email) {
        return this.request(apiRequestTypes.POST, apiEndpoints.FORGOT, {
            email,
        });
    }

    passwordReset(email, password, passwordConfirmation, token) {
        return this.request(apiRequestTypes.POST, apiEndpoints.RESET, {
            email,
            password,
            password_confirmation: passwordConfirmation,
            token,
        });
    }

    getStyles() {
        return this.requestToken(apiRequestTypes.GET, apiEndpoints.STYLES);
    }

    downloadSound(soundId) {
        return this.requestToken(apiRequestTypes.GET, `${apiEndpoints.DOWNLOAD_SOUND}${soundId}`);
    }

    getSharedPacks(name, styles, limit, page) {
        let params = {};

        if (name !== '') {
            params.name = name;
        }
        if (styles.length > 0) {
            params['styles[]'] = styles;
        }

        if (limit) {
            params.num_per_page = limit;
        }
        if (page) {
            params.page = page;
        }

        return this.requestToken(apiRequestTypes.POST, apiEndpoints.SHARED_PACKS, params);
    }

    getPlans() {
        return this.request(apiRequestTypes.GET, apiEndpoints.PLANS, {});
    }

    customerPortalCreate() {
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.CUSTOMER_PORTAL_CREATE, {});
    }

    getPack(packId) {
        return this.requestToken(apiRequestTypes.GET, `${apiEndpoints.GET_PACK}${packId}`);
    }

    voucherCodeRedeem(voucherCode) {
        const params = {
            voucher_code: voucherCode,
        };
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.VOUCHER_CODE_REDEEM, params);
    }

    checkoutCreate(packId) {
        let params = {

        };
        if (packId > 0) {
            params.pack_id = packId;
        }
        return this.requestToken(apiRequestTypes.POST, apiEndpoints.STRIPE_CHECKOUT_CREATE, params);
    }

    checkoutBundleCreate(stripePriceId) {
        return this.requestToken(apiRequestTypes.POST, `${apiEndpoints.STRIPE_CHECKOUT_BUNDLE_CREATE}${stripePriceId}`);
    }

    stripeGetSession(sessionId) {
        return this.requestToken(apiRequestTypes.GET, `${apiEndpoints.STRIPE_GET_SESSION}${sessionId}`);
    }

    requestToken(type, endpoint, params, token) {
        const storageToken = storage.get(storageKeys.ACCESS_TOKEN);

        return this.http.request(this.url + endpoint, type, params, typeof token !== 'undefined' ? token : storageToken);
    }

    request(type, endpoint, params) {
        return this.http.request(this.url + endpoint, type, params);
    }

    parseRespone(response) {
        let parsedResponse = {
            isSuccess: true,
            data: response.data,
            errors: [],
            error: '',
        };
        if (typeof response.errors !== 'undefined') {
            parsedResponse.isSuccess = false;
            parsedResponse.errors = response.errors;
        }
        if (typeof response.error !== 'undefined' && response.error !== '') {

            parsedResponse.isSuccess = false;
            parsedResponse.error = response.error;
        }
        if (typeof response.data.error !== 'undefined' && response.data.error !== '') {
            parsedResponse.isSuccess = false;
            parsedResponse.error = response.data.error;
        }

        if (typeof response.data.data !== 'undefined') {
            parsedResponse.data = response.data.data;
        }

        return parsedResponse;
    }
}
