import { POST } from "../constants/apiRequestTypes";

export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_ERROR   = 'GET_PLANS_ERROR';

export const getPlans = () => dispatch => {
    return dispatch({
        type: POST,
        onPending: GET_PLANS_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: GET_PLANS_SUCCESS,
                response
            });
        },
        onFailure: GET_PLANS_ERROR,
        promise: (api) => {
            return api.getPlans();
        },
    })
};
