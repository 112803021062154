import * as heroSectionModes from '../constants/heroSectionModes';
import { isLandingPageEnabled } from './landing';

export const featuresRoute = '/features';
export const landingRoute = '/bundle-sale';
export const downloadLink = '/download';
export const freeTrialLink = '/free-trial';
export const catalogLink = '/catalog';

export const subscriptionPayLink = '/';
export const perUsePayLink = '/';

export const helpCenterLink = 'https://support.capsule.audio';

export const socialLinks = {
    instagram: 'https://www.instagram.com/capsule_audio/',
    facebook: 'https://www.facebook.com/capsule.audio',
    youtube: 'https://www.youtube.com/channel/UCGNvQXVKoZFdPjIaer5_YSg',
};

// TODO take it from a server, currently I don't see the way
// to obtain it properly
export const copyrightYear = new Date().getFullYear();

export const heroSectionMode = heroSectionModes.MODE_VIDEO;

export let footerLinks = {
    left: [
        {url: '/features', text: 'Features', isInternal: true, onClick: () => { window.scrollTo(0, 0)}},
        {url: '/download', text: 'Download', isInternal: true},
        {url: '/pricing', text: 'Pricing', isInternal: true},
        {url: '/catalog', text: 'Catalog', isInternal: true},
        {url: helpCenterLink, text: 'Help center', isInternal: false},
        {url: '/dashboard', text: 'My account', isInternal: true},
    ],
    right: [
        {url: `${helpCenterLink}/support/solutions/articles/77000117974`, text: 'FAQ', isInternal: false},
        {url: `${helpCenterLink}/support/solutions/articles/77000478681`, text: 'Portal user manual', isInternal: false},
        {url: `${helpCenterLink}/support/solutions/articles/77000435829`, text: 'Plugin user manual', isInternal: false},
        {url: '/privacy', text: 'Privacy policy', isInternal: true},
        {url: '/terms', text: 'Terms of use', isInternal: true},
        {url: 'mailto:support@capsule.audio', text: 'Contact', isInternal: false},
    ]
};

if (isLandingPageEnabled) {
    footerLinks.left = [{
        url: landingRoute,
        text: 'Bundle sale',
        isInternal: true,
        onClick: () => { window.scrollTo(0, 0)}
    }, ...footerLinks.left]
}

export const pricePerLibrary = 29;

export const capsuleDefaultPrice = '19,99';

export const placeholderMonthPrice = ' ';

export const contactEmail = 'hello@fendrbendr.com';

export const PASSWORD_MIN_LENGTH = 8;
export const VOUCHER_LENGTH = 32;

export const updateUserDelay = 10;

const testAudio = document.createElement('audio');
export const isSupportOggAudio = testAudio.canPlayType('audio/ogg') !== '';

export const isGTMEnabled = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID !== '';

export const introVideoYoutubeHash = 'msJcNQleH_4';

export const testimonialsAutoSlideInterval = 10; // in seconds

export const emailDomainDNSLookupAttempts = 3;

export const nonLoggedInUsersRedirectUrl = 'signup';

export const isTapfiliate = process.env.REACT_APP_TAPFILIATE_ACCOUNT_ID != 'none';
