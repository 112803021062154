import * as uiActions from "../actions/ui";
import * as userActions from "../actions/user";
import * as paymentActions from "../actions/payment";
import * as playerActions from "../actions/player";
import * as packActions from "../actions/pack";
import * as systemActions from "../actions/system";
import storage from '../storages/storage';
import * as storageKeys from '../constants/storageKeys';
import { initialState as initialUserState } from './user';
import { defaultSplashPageMessage } from '../config/ui';

const initialSignUpState = /*cookie.get(storageKeys.EMAIL_TO_VERIFICATE) ? {
    isSignUpPending: false,
    signUpErrors: {},
    isSignUpSuccess: true,
} : */{
    isSignUpPending: false,
    signUpErrors: {},
    isSignUpSuccess: false,
};
const initialPasswordResetState = {
    isPasswordResetPending: false,
    isPasswordResetSuccess: false,
    passwordResetErrors: {},
};
const initialForgotState = {
    isForgotPending: false,
    isForgotSuccess: false,
    forgotError: '',
};
const initialLoginState = {
    isLoginPending: false,
    loginError: '',
};
const initialChangePasswordState = {
    isChangePasswordPending: false,
    isChangePasswordSuccess: false,
    changePasswordErrors: {},
};
const initialUpdateMeState = {
    isUpdateMePending: false,
    updateMeErrors: {},
    isUpdateMeSuccess: false,
};
const initialPaymentState = {
    isPaymentResultPending: false,
    paymentResultError: '',
    paymentResultSuccess: '',
    paymentResultSubscriptionStatus: '',
    paymentPackId: 0,
};
const initialVerificationState = {
    isResendVerificationPending: false,
    isResendVerificationSuccess: false,
    isResendVerificationeError: false,
};
const initialPlayerState = {
    currentPlayAudio: null,
    currentPlayInfo: {},
    isPlayerPlaying: false,
    isPlayer: false,
    isDownloadAudioError: false,
};
const initialState = {
    isVideoSound: false,
    isMobileMenuVisible: false,
    isHeader: true,
    isCookieBannerShow: storage.isNot(storageKeys.COOKIE_BANNER_CLOSED),
    isVideoIntroComplete: false,

    ...initialLoginState,
    ...initialSignUpState,
    ...initialForgotState,
    ...initialPasswordResetState,
    ...initialVerificationState,

    ...initialChangePasswordState,
    ...initialUpdateMeState,

    packCheckoutPendingId: null,

    ...initialPlayerState,
    ...initialPaymentState,

    verificationStatus: null,
    isScreenLoader: false,
    isGetMePending: !initialUserState.isLogged && initialUserState.authToken !== '',
    isCustomerPortalPending: false,

    isVerificationPopupFocused: false,
    isInternalError: false,
    isHeroVideoPlaying: false,
    checkoutPage: storage.get(storageKeys.CHECKOUT_PAGE) ? storage.get(storageKeys.CHECKOUT_PAGE) : '',
    portalPage: storage.get(storageKeys.PORTAL_PAGE) ? storage.get(storageKeys.PORTAL_PAGE) : '',

    isPaymentCancel: false,
    playingVideo: {
        type: '',
        link: '',
    },

    splashPageMessage: defaultSplashPageMessage,
    isVerificationModal: false,
};

function ui(state = initialState, action) {
    switch (action.type) {
        case uiActions.SHOW_VERIFICATION_MODAL:
            return {...state, isVerificationModal: true};
        case uiActions.HIDE_VERIFICATION_MODAL:
            return {...state, isVerificationModal: false};
        case uiActions.VIDEO_INTRO_COMPLETE:
            return {...state, isVideoIntroComplete: true};
        case uiActions.STOP_VIDEO:
            return {...state, playingVideo: {link: '', type: ''}};
        case uiActions.PLAY_VIDEO:
            return {...state, playingVideo: {link: action.link, type: action.videoType}};
        case uiActions.PLAY_HERO_VIDEO:
            return {...state, isHeroVideoPlaying: true};
        case paymentActions.PAYMENT_CANCEL_SHOWN:
            return {...state, isPaymentCancel: false, checkoutPage: ''};
        case paymentActions.PAYMENT_CANCEL:
            return {...state, isPaymentCancel: true};
        case paymentActions.STRIPE_GET_SESSION_ERROR:
            return {
                ...state,
                isPaymentResultPending: false,
                paymentResultError: action.error,
                paymentResultSuccess: '',
                isScreenLoader: false,
                paymentPackId: 0,
            };
        case paymentActions.STRIPE_GET_SESSION_SUCCESS:
            return {
                ...state,
                isPaymentResultPending: false,
                isScreenLoader: false,
                paymentResultError: '',
                paymentResultSuccess: action.response.data.payment_status,
                paymentResultSubscriptionStatus: action.response.data.subscription_status,
                paymentPackId: parseInt(action.response.data.pack_id),
            };
        case paymentActions.STRIPE_GET_SESSION_PENDING:
            return {...state, isPaymentResultPending: true, isScreenLoader: true};
        case uiActions.INTERNAL_SERVER_ERROR:
            return {...state, isScreenLoader: false, isLoginPending: false, isInternalError: true};
        case uiActions.UNFOCUS_VERIFICATION_POPUP:
            return {...state, isVerificationPopupFocused: false};
        case uiActions.FOCUS_VERIFICATION_POPUP:
            return {...state, isVerificationPopupFocused: true};
        case paymentActions.CUSTOMER_PORTAL_SUCCESS:
            return {...state, isCustomerPortalPending: false};
        case paymentActions.CUSTOMER_PORTAL_ERROR:
            alert(action.error);
            return {...state, isCustomerPortalPending: false};
        case paymentActions.CUSTOMER_PORTAL_PENDING:
            return {...state, isCustomerPortalPending: true};

        case userActions.RESET_VERIFICATION_STATUS:
            return {...state, verificationStatus: null};

        case userActions.VERIFICATION_STATUS:
            return {...state, verificationStatus: action.status, isScreenLoader: action.isLoader, isLoginPending: action.isLoader};
        case userActions.LOGOUT_ERROR:
        case userActions.LOGOUT_SUCCESS:
        case userActions.LOGOUT_PENDING:
        case systemActions.HEALTHCHECK_INITIAL_PENDING:
            return {...state, isScreenLoader: true};
        case systemActions.HEALTHCHECK_ERROR:
            return {...state, isScreenLoader: false};
        case systemActions.HEALTHCHECK_SUCCESS:
            return {...state, isScreenLoader: false};
        case uiActions.CLEAR_CHANGE_PASSWORD_ERRORS:
            return {...state, ...initialChangePasswordState};
        case userActions.CHANGE_PASSWORD_SUCCESS:
            return {...state, isChangePasswordPending: false, changePasswordErrors: initialChangePasswordState.changePasswordErrors, isChangePasswordSuccess: true};
        case userActions.CHANGE_PASSWORD_ERROR:
            return {...state, isChangePasswordPending: false, changePasswordErrors: action.errors, isChangePasswordSuccess: false};
        case userActions.CHANGE_PASSWORD_PENDING:
            return {...state, isChangePasswordPending: true, isChangePasswordSuccess: false,};

        case userActions.UPDATE_ME_SUCCESS:
            return {...state, isUpdateMePending: false, updateMeErrors: {}, updateMeError: '', isUpdateMeSuccess: true};
        case userActions.UPDATE_ME_ERROR:
            return {...state, isUpdateMePending: false, updateMeErrors: action.errors, isUpdateMeSuccess: false};
        case userActions.UPDATE_ME_PENDING:
            return {...state, isUpdateMePending: true, updateMeErrors: {}, updateMeError: '', isUpdateMeSuccess: false};

        case packActions.DOWNLOAD_SOUND_PENDING:
            return {...state,  isDownloadAudioError: false,};
        case playerActions.PLAYER_PAUSE:
        case playerActions.PLAYER_STOP:
            return {...state, isPlayerPlaying: false};
        case playerActions.PLAYER_CONTINUE:
            return {...state, isPlayerPlaying: true};
        case playerActions.HIDE_PLAYER:
            return {...state, ...initialPlayerState};
        case playerActions.SHOW_PLAYER:
            return {...state,
                isPlayer: true,
                currentPlayAudio: action.pack.previewLink,
                isPlayerPlaying: true,
                currentPlayInfo: {
                    pack: action.pack,
                    title: action.title,
                    image: action.image,
                    isDownloadAudioError: false,
                }
            };
        case playerActions.PLAYER_PLAY:
            return {...state, currentPlayAudio: action.url, isPlayer: true, currentPlayInfo: {
                title: action.title,
                tags: action.tags,
            }, isPlayerPlaying: true,};
        case paymentActions.CHECKOUT_PENDING:
            return {...state, packCheckoutPendingId: action.packId, isPaymentResultPending: true, paymentResultError: '', paymentResultSuccess: ''};
        case paymentActions.CLEAR_PAYMENT_RESULT:
            return {...state, isPaymentResultPending: false, paymentResultError: '', paymentResultSuccess: ''};
        case paymentActions.CHECKOUT_ERROR:
            // TODO remove, temporary
            if (typeof action.error !== 'undefined') {
                alert(action.error);
            } else if (typeof action.response.error !== 'undefined') {
                alert(action.response.error);
            }
            return {...state, packCheckoutPendingId: initialState.packCheckoutPendingId, isPaymentResultPending: initialPaymentState.isPaymentResultPending};

        case uiActions.CLEAR_SIGN_ERRORS:
            return {...state, ...initialSignUpState, ...initialLoginState, ...initialForgotState};

        case userActions.FORGOT_SUCCESS:
            return {...state, isForgotPending: false, forgotError: '', isForgotSuccess: true};
        case userActions.FORGOT_ERROR:
            return {...state, isForgotPending: false, forgotError: action.error, isForgotSuccess: false};
        case userActions.FORGOT_PENDING:
            return {...state, isForgotPending: true};

        case userActions.PASSWORD_RESET_SUCCESS:
            return {...state, isPasswordResetPending: false, passwordResetErrors: {}, isPasswordResetSuccess: true};
        case userActions.PASSWORD_RESET_ERROR:
            return {...state, isPasswordResetPending: false, passwordResetErrors: action.errors, isPasswordResetSuccess: false};
        case userActions.PASSWORD_RESET_PENDING:
            return {...state, isPasswordResetPending: true};

        case userActions.GET_ME_SUCCESS:
        case userActions.LOGIN_SUCCESS:
            return {...state, isLoginPending: false, isScreenLoader: false, loginError: '', isGetMePending: false};
        case userActions.LOGIN_ERROR:
            return {...state, isLoginPending: false, isScreenLoader: false, loginError: action.error};
        case userActions.LOGIN_PENDING:
            return {...state, isLoginPending: true};
        case userActions.GET_ME_PENDING:
            return {...state, isLoginPending: true, isGetMePending: true};

        case userActions.RESEND_VERIFICATION_RESET:
            return {...state, ...initialVerificationState};
        case userActions.RESEND_VERIFICATION_ERROR:
            return {...state, isResendVerificationPending: false, isResendVerificationeError: true, isResendVerificationSuccess: false};
        case userActions.RESEND_VERIFICATION_SUCCESS:
            return {...state, isResendVerificationPending: false, isResendVerificationeError: false, isResendVerificationSuccess: true, isVerificationModal: false};
        case userActions.RESEND_VERIFICATION_PENDING:
            return {...state, isResendVerificationPending: true};

        case uiActions.HIDE_SIGN_UP_SUCCESS_MESSAGE:
            return {...state, isSignUpSuccess: false, signUpErrors: {}};
        case userActions.REGISTER_SUCCESS:
            return {...state, isSignUpPending: false, isSignUpSuccess: true};
        case userActions.REGISTER_ERROR:
            return {...state, isSignUpPending: false, isSignUpSuccess: false, signUpErrors: action.errors};
        case userActions.REGISTER_PENDING:
            return {...state, isSignUpPending: true};
        case userActions.REGISTER_PENDING_STOP:
            return {...state, isSignUpPending: false};

        case uiActions.HIDE_HEADER:
            return {...state, isHeader: false};
        case uiActions.SHOW_HEADER:
            return {...state, isHeader: true};
        case uiActions.CLOSE_COOKIE_BANNER:
            return {...state, isCookieBannerShow: false};
        case uiActions.HIDE_MOBILE_MENU:
            return {...state, isMobileMenuVisible: false};
        case uiActions.SHOW_MOBILE_MENU:
            return {...state, isMobileMenuVisible: true};
        case uiActions.TOGGLE_VIDEO_SOUND:
            return {...state, isVideoSound: !state.isVideoSound};
        default:
            return state;
    }
}

export default ui;
