import React from "react";
import PropTypes from 'prop-types';

export default class CenteredContentWrapper extends React.PureComponent {
    render() {
        let { className, children } = this.props;

        return (
            <div className={'centered-content-wrapper' + (className ? ' ' + className : '')}>
                {children}
            </div>
        );
    }
}

CenteredContentWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
