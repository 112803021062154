import React from "react";
import PropTypes from 'prop-types';
import { Route as RouteComponent } from 'react-router-dom';
import { Redirect, withRouter } from "react-router-dom";
import {nonLoggedInUsersRedirectUrl} from "../../config/app";

class ProtectedRoute extends React.PureComponent {
    componentDidMount() {
        /* let { getMe, authToken, isLoader, userData } = this.props;

        if (authToken && !isLoader && Object.keys(userData).length === 0) {
            getMe();
        }*/
    }

    render() {
        let { path, component, attributes, authToken, redirectUnloggedTo } = this.props;

        if (!authToken) {
            const to = redirectUnloggedTo ? redirectUnloggedTo : `/${nonLoggedInUsersRedirectUrl}`;
            return <Redirect to={to}/>
        }

        return (
            <div>
                <RouteComponent path={path} component={component} {...attributes}/>
            </div>
        )
    }
}

ProtectedRoute.propTypes = {
    path: PropTypes.string.isRequired,
    component: PropTypes.object,
    attributes: PropTypes.object,
    isProtected: PropTypes.bool,

    isLogged: PropTypes.bool.isRequired,
    userData: PropTypes.object,
    getMe: PropTypes.func.isRequired,

    isLoader: PropTypes.bool.isRequired,
    redirectUnloggedTo: PropTypes.string,
};

export default withRouter(ProtectedRoute);
