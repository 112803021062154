import localStorageObject from '../storages/localStorage';

const tapfiliateRefStorageKey = 'capsule-tapfiliate-ref';
const tapfiliateRefStorageKeyAPI = 'capsule-tapfiliate-ref-api';

export const tapfiliateSaveRef = (ref) => {
    localStorageObject.set(tapfiliateRefStorageKey, ref);
}

export const tapfiliateGetRef = () => {
    return localStorageObject.get(tapfiliateRefStorageKey);
}

export const tapfiliateSaveRefAPI = (ref) => {
    localStorageObject.set(tapfiliateRefStorageKeyAPI, ref);
}

export const tapfiliateGetRefAPI = () => {
    return localStorageObject.get(tapfiliateRefStorageKeyAPI);
}