import storage from '../storages/storage';
import { USER_PACK_DATA } from '../constants/storageKeys';
import {mockPackData} from "../mockers/pack";
import {isSupportOggAudio} from "../config/app";
import {
    awsPackImageExtensionCompressed,
    awsPackImagePrefix,
    awsPackImagePrefixCompressed,
    awsPackImagePrefixCompressedRect
} from "../config/packs";

export const filterPacksById = purchasedPacksData => {
    let packsById = {};
    if (!purchasedPacksData) {
        return packsById;
    }
    purchasedPacksData.forEach(pack => {
        packsById[pack.id] = pack;
    });
    return packsById;
};

export const formatPrice = price => {
    return price.toFixed(2).toString().split('.').join(',');
};

export const getUserPackImage = pack => {
    if (!pack.packData) {
        return null;
    }
    return getPackImage(pack.packData);
};

export const getPackImage = pack => {
    if (pack.image_vertical && pack.image_vertical !== '') {
        return pack.image_vertical;
    }

    return pack.image;
}
export const getPackImageRect = pack => {
    return pack.image_rect;
}

export const getPackLoadedImage = (packImages, pack) => {
    if (!packImages) {
        return fallbackPackImage(pack);
    }

    if (typeof packImages[pack.id] === "undefined") {
        return fallbackPackImage(pack);
    }

    if (!packImages[pack.id]) {
        return fallbackPackImage(pack);
    }

    return packImages[pack.id];
}
const fallbackPackImage = (pack) => {
    return pack.image_rectangular !== '' ? pack.image_rectangular : pack.image;
}
const getPackImageData = (pack, isRect) => {
    let imageUrl = isRect ? pack.image_rect : getPackImage(pack);

    if (!imageUrl) {
        return null;
    }

    let imageUrlParts = imageUrl.split(awsPackImagePrefix);
    let imageNameParts = imageUrlParts[1].split('.');
    let imageName = imageNameParts.slice(0, imageNameParts.length - 1).join('.');

    return {
        imageUrlParts,
        imageName,
    }
}
export const getPackImageCompressed = pack => {
    const packImageData = getPackImageData(pack);

    if (!packImageData) {
        return null;
    }

    const {
        imageUrlParts,
        imageName,
    } = packImageData;

    return `${imageUrlParts[0]}${awsPackImagePrefix}${awsPackImagePrefixCompressed}${imageName}.${awsPackImageExtensionCompressed}`;
};
export const getPackImageCompressedRect = pack => {
    const packImageData = getPackImageData(pack, true);

    if (!packImageData) {
        return null;
    }

    const {
        imageUrlParts,
        imageName,
    } = packImageData;

    return `${imageUrlParts[0]}${awsPackImagePrefix}${awsPackImagePrefixCompressedRect}${imageName}.${awsPackImageExtensionCompressed}`;
};

export const isPackFeatured = pack => {
    return pack.featured === 1 && pack.image_rect !== '' && pack.image_rect !== null;
}

export const getPackDescription = (pack) => {
    let styleNames = [];

    pack.styles.forEach(style => {
        styleNames.push(style.name);
    });

    return styleNames.join(', ');
};

export const getUserPackStorageKey = (packId) => {
    return `${USER_PACK_DATA}${packId}`;
}

export const getUserPackData = (pack, userPacksData) => {
    if (userPacksData && !!userPacksData[pack.id]) {
        return userPackDataWithExpiration(userPacksData[pack.id], false);
    }

    const packData = storage.get(getUserPackStorageKey(pack.id));
    const now = +new Date();

    if (packData && packData.payload) {
        if ((packData.expires - now) / 1000 <= 0) {
            return userPackDataWithExpiration(packData.payload, true);
        }

        return userPackDataWithExpiration(packData.payload, false);
    }

    return userPackDataWithExpiration(null, false);
}

const userPackDataWithExpiration = (pack, isExpired) => {
    return {
        ...pack,
        isExpired,
    }
}

export const getPackPreviewLink = (previewLink) => {
    return `${previewLink}?${Math.random()}`;
};

export const getPackData = (pack) => {
    pack = mockPackData(pack);

    const previewKeys = Object.keys(pack.preview_files);
    const isPreview = previewKeys.length > 0;

    let previewLink = '';
    if (isPreview) {
        let previewIndex = 0;

        previewKeys.forEach((previewKey, index) => {
            if (previewKey.toLowerCase().indexOf(isSupportOggAudio ? 'ogg' : 'mp3') >= 0) {
                previewIndex = index;
            }
        });

        previewLink = pack.preview_files[previewKeys[previewIndex]];
    }

    return {
        pack,
        isPreview,
        previewLink,
    }
}

export const getSharedPacksById = (packs) => {
    let packsByIds = {};

    packs.forEach(pack => {
        packsByIds[pack.id] = pack;
    });

    return packsByIds;
};