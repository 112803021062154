import {POST} from "../constants/apiRequestTypes";

export const HEALTHCHECK_INITIAL_PENDING = 'HEALTHCHECK_INITIAL_PENDING';
export const HEALTHCHECK_PENDING = 'HEALTHCHECK_PENDING';
export const HEALTHCHECK_SUCCESS = 'HEALTHCHECK_SUCCESS';
export const HEALTHCHECK_ERROR   = 'HEALTHCHECK_ERROR';

export const healthCheck = (success) => dispatch => {
    return dispatch({
        type: POST,
        onPending: success ? HEALTHCHECK_INITIAL_PENDING : HEALTHCHECK_PENDING,
        onSuccess: (response) => {
            dispatch({
                type: HEALTHCHECK_SUCCESS,
                response
            });

            if (typeof success === 'function') {
                success();
            }
        },
        onFailure: HEALTHCHECK_ERROR,
        promise: (api) => {
            return api.healthcheck();
        },
    })
};

