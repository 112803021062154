import * as voucherActions from '../actions/voucher';

const initialState = {
    isVoucherRedemptionPending: false,
    isVoucherRedemptionSuccess: false,
    voucherRedemptionErrors: null,
};

function voucher(state = initialState, action) {
    switch (action.type) {
        case voucherActions.VOUCHER_CODE_REDEMPTION_ERROR:
            return {
                ...state,
                isVoucherRedemptionPending: false,
                isVoucherRedemptionSuccess: false,
                voucherRedemptionErrors: typeof action.error.voucher_code !== 'undefined' ? action.error.voucher_code : [action.error],
            };
        case voucherActions.VOUCHER_CODE_REDEMPTION_SUCCESS:
            return {
                ...state,
                isVoucherRedemptionPending: false,
                voucherRedemptionErrors: null,
                isVoucherRedemptionSuccess: true,
            };
        case voucherActions.VOUCHER_CODE_REDEMPTION_PENDING:
            return {
                ...state,
                isVoucherRedemptionPending: true,
                voucherRedemptionErrors: null,
                isVoucherRedemptionSuccess: false,
            };
        default:
            return state;
    }
}

export default voucher;
