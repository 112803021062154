import * as userActions from "../actions/user";
import * as paymentActions from "../actions/payment";
//import cookie from '../storages/cookie';
import storage from '../storages/storage';
import * as storageKeys from '../constants/storageKeys';
import { filterPacksById } from '../helpers/pack';
import * as uiActions from "../actions/ui";

const accessToken = storage.get(storageKeys.ACCESS_TOKEN);
const userData = {};//storage.get(storageKeys.USER_DATA);

const initialLogoutState = {
    isLogoutPending: false,
    isLogoutSuccess: false,
    isLogoutError: false,
};
export const initialState = {
    isLogged: !!(accessToken && accessToken !== '' && typeof accessToken !== 'undefined' && Object.keys(userData).length > 0),
    userData: userData ? userData : {},

    authToken: accessToken ? accessToken : '',
    isNeedUpdate: true,
    purchasedPacksById: userData && userData.purchased_packs ? filterPacksById(userData.purchased_packs) : {},

    isGetMeSilentPending: false,

    ...initialLogoutState,
};

const getUserPacks = (action) => {
    return {
        purchasedPacks: action.response.data.purchased_packs,
        purchasedPacksById: filterPacksById(action.response.data.purchased_packs),
    }
}
function user(state = initialState, action) {
    switch (action.type) {
        case userActions.GET_ME_SILENT_SUCCESS:
            return {
                ...state,
                isGetMeSilentPending: false,
                ...getUserPacks(action),
            };
        case userActions.GET_ME_SILENT_PENDING:
            return {...state, isGetMeSilentPending: true};
        case userActions.NEED_UPDATE_CALL:
            return {...state, isNeedUpdate: true};
        case paymentActions.STRIPE_GET_SESSION_SUCCESS:
            return {...state, userData: {}};
        case userActions.UNAUTHORIZED_ERROR:
            return {...state, isLogged: false, authToken: '', userData: {}};
        case uiActions.INTERNAL_SERVER_ERROR:
            return {...state, isLogged: false};
        case userActions.PASSWORD_RESET_SUCCESS:
        case userActions.FORGOT_SUCCESS:
            return {...state, userData: {}, isLogged: false};
        case userActions.LOGOUT_ERROR:
            return {...state, ...initialLogoutState, isLogoutError: true};
        case userActions.LOGOUT_SUCCESS:
            return {...state, ...initialLogoutState, isLogoutSuccess: true};
        case userActions.LOGOUT_PENDING:
            return {...state, ...initialLogoutState, isLogoutPending: true};
        case userActions.GET_ME_SUCCESS:
            return {
                ...state,
                isLogged: true,
                userData: action.response.data,
                isNeedUpdate: false,
                ...getUserPacks(action),
            };
        case userActions.VERIFICATION_STATUS:
            return {...state, authToken: action.token};
        case userActions.LOGIN_SUCCESS:
            return {...state, authToken: action.response.data.access_token};
        case userActions.REGISTER_SUCCESS:
            return {...state, isLogged: true, userData: action.response.data, authToken: action.response.data.auth.access_token};
        default:
            return state;
    }
}

export default user;
