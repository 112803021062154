export const isLandingPageEnabled = false;
export const isLandingPageDefaultRoute = false;

export const bundlePriceMin = '199';
export const bundlePriceMax = '928';
export const saleDateEnd = "2023-11-28T08:00:00+0000";

const subTitle = 'The emotive ensemble';

export const packsData = {
    orchestral: [
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/arco-classico.webp'),
            title: 'Arco Classico',
            subtitle: subTitle,
            packId: 15,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/arco-solista.webp'),
            title: 'Arco Solista',
            subtitle: subTitle,
            packId: 36,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/zephyr.webp'),
            title: 'Zephyr',
            subtitle: subTitle,
            packId: 35,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/sanctus.webp'),
            title: 'Sanctus',
            subtitle: subTitle,
            packId: 29,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/aeolus.webp'),
            title: 'Aeolus',
            subtitle: subTitle,
            packId: 21,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/aeolus-solo.webp'),
            title: 'Aeolus Solo',
            subtitle: subTitle,
            packId: 40,
        },
        {
            staticImage: require('../assets/images/landing/capsules/orchestral-series/heartstrings.webp'),
            title: 'Heart Strings',
            subtitle: subTitle,
            packId: 33,
        },
    ],
    retroSeries: [
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/retro-essentials.webp'),
            title: 'Retro essentials',
            subtitle: subTitle,
            packId: 18,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/retro-brass.webp'),
            title: 'Retro brass',
            subtitle: subTitle,
            packId: 22,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/retro-strings.webp'),
            title: 'Retro strings',
            subtitle: subTitle,
            packId: 32,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/neon.webp'),
            title: 'Neon',
            subtitle: subTitle,
            packId: 2,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/80s-analog-hits.webp'),
            title: '80\'s Analog Hits',
            subtitle: subTitle,
            packId: 14,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/monochrome.webp'),
            title: 'Monochrome',
            subtitle: subTitle,
            packId: 7,
        },
        {
            staticImage: require('../assets/images/landing/capsules/retro-series/polychrome.png'),
            title: 'Polychrome',
            subtitle: subTitle,
            packId: 38,
        },
    ],
    firestarters: [
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/thunderdome.webp'),
            title: 'Thunderdome',
            subtitle: subTitle,
            packId: 13,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/nightfall.webp'),
            title: 'Nightfall',
            subtitle: subTitle,
            packId: 41,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/ghostwriter.png'),
            title: 'Ghostwriter',
            subtitle: subTitle,
            packId: 47,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/circuit-city.webp'),
            title: 'Circuit city',
            subtitle: subTitle,
            packId: 43,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/stratus-drip.webp'),
            title: 'Stratus drip',
            subtitle: subTitle,
            packId: 30,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/grit.webp'),
            title: 'Grit',
            subtitle: subTitle,
            packId: 8,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/ether.webp'),
            title: 'Ether',
            subtitle: subTitle,
            staticVideo: require('../assets/video/landing/ether-1.mp4'),
            packId: 6,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/exodus.webp'),
            title: 'Exodus',
            subtitle: subTitle,
            packId: 3,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/ripple.webp'),
            title: 'Ripple',
            subtitle: subTitle,
            packId: 19,
        },
        {
            staticImage: require('../assets/images/landing/capsules/firestarters/onyx.webp'),
            title: 'Onyx',
            subtitle: subTitle,
            packId: 17,
        },
    ],
    keys: [
        {
            staticImage: require('../assets/images/landing/capsules/keys/black-ivory.webp'),
            title: 'Black ivory',
            subtitle: subTitle,
            packId: 10,
        },
        {
            staticImage: require('../assets/images/landing/capsules/keys/felt-piano.webp'),
            title: 'Felt Piano',
            subtitle: subTitle,
            packId: 28,
        },
        {
            staticImage: require('../assets/images/landing/capsules/keys/grapevine.webp'),
            title: 'Grapevine',
            subtitle: subTitle,
            packId: 4,
        },
        {
            staticImage: require('../assets/images/landing/capsules/keys/solid-gold.webp'),
            title: 'Solid Gold',
            subtitle: subTitle,
            packId: 11,
        },
        {
            staticImage: require('../assets/images/landing/capsules/keys/recital.webp'),
            title: 'Recital',
            subtitle: subTitle,
            packId: 1,
        },
    ],
    experimentalAndGems: [
        {
            staticImage: require('../assets/images/landing/capsules/experimenta-and-gems/guitaret.webp'),
            title: 'Guitaret',
            subtitle: subTitle,
            packId: 20,
        },
        {
            staticImage: require('../assets/images/landing/capsules/experimenta-and-gems/lamelle.webp'),
            title: 'Lamelle',
            subtitle: subTitle,
            packId: 5,
        },
        {
            staticImage: require('../assets/images/landing/capsules/experimenta-and-gems/arco-nuovo.webp'),
            title: 'Arco nuovo',
            subtitle: subTitle,
            packId: 9,
        },
    ]
};
export const videoBlocksData = [
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/a-few-sounds.jpg'),
        title: 'Demo sounds',
        video: 'fAbYEDxvV6I',
    },
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/dive-into-capsule.webp'),
        title: 'Dive into Capsule',
        video: 'ulJ3krTJHYI',
    },
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/Capsule-strings-tutorial-opt-01.webp'),
        title: 'How to use strings in Capsule',
        video: 'Ess-NgYV9tk',
    },
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/Jay-Cactus-making-a-drill-beat-with-Capsule-opt-02.webp'),
        title: 'Jay Cactus making a drill beat with Capsule',
        video: 'GDt5apDeO6E',
    },
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/Dixon-beats-exploring-Capsule-opt-01.webp'),
        title: 'Dixon beats exploring Capsule',
        video: 'UpVuaCNfU9U',
    },
    {
        thumbnail: require('../assets/images/landing/videoThumbnails/Al-Be-Capsule-plugin-review-opt-02.webp'),
        title: 'Al Be Capsule plugin review',
        video: 'WSv94m9dvcw',
    },
];
export const capsulePrice = 29.00;
export const bundleList = [
    {title: 'Arco Classico', price: capsulePrice},
    {title: 'Arco Solista', price: capsulePrice},
    {title: 'Zephyr', price: capsulePrice},
    {title: 'Sanctus', price: capsulePrice},
    {title: 'Heartstring', price: capsulePrice},
    {title: 'Aeolus', price: capsulePrice},
    {title: 'Aeolus Solo', price: capsulePrice},
    {title: 'Retro essentials', price: capsulePrice},
    {title: 'Retro brass', price: capsulePrice},
    {title: 'Retro strings', price: capsulePrice},
    {title: 'Neon', price: capsulePrice},
    {title: '80\'s Analog hits', price: capsulePrice},
    {title: 'Monochrome', price: capsulePrice},
    {title: 'Thunderdome', price: capsulePrice},
    {title: 'Nightfall', price: capsulePrice},
    {title: 'Ghostwriter', price: capsulePrice},
    {title: 'Circuit city', price: capsulePrice},
    {title: 'Stratus drip', price: capsulePrice},
    {title: 'Grit', price: capsulePrice},
    {title: 'Ether', price: capsulePrice},
    {title: 'Exodus', price: capsulePrice},
    {title: 'Ripple', price: capsulePrice},
    {title: 'Onyx', price: capsulePrice},
    {title: 'Black ivory', price: capsulePrice},
    {title: 'Felt piano', price: capsulePrice},
    {title: 'Grapevine', price: capsulePrice},
    {title: 'Solid gold', price: capsulePrice},
    {title: 'Recital', price: capsulePrice},
    {title: 'Guitaret', price: capsulePrice},
    {title: 'Lamelle', price: capsulePrice},
    {title: 'Arco nuovo', price: capsulePrice},
    {title: 'Polychrome', price: capsulePrice},
];
