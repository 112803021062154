import * as systemActions from '../actions/system';

const initialState = {
    serverTime: null,
};

function system(state = initialState, action) {
    switch (action.type) {
        case systemActions.HEALTHCHECK_SUCCESS:
            return {...state, serverTime: action.response.data.server_time};
        case systemActions.HEALTHCHECK_ERROR:
            return {...state,};
        default:
            return state;
    }
}

export default system;
