import * as actions from '../constants/actions';

const delayedActionsMiddleware = () => {
    return next => action => {
        const { type, first, second, delay, ...rest } = action;

        if (type !== actions.DELAYED_ACTION) {
            // not delayed action
            return next(action);
        }

        next({...rest, type: first});

        setTimeout(() => {
            next({...rest, type: second});
        }, delay * 1000)
    }
};

export default delayedActionsMiddleware;
