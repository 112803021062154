import { connect } from 'react-redux';
import PublicRoute from '../../components/routes/PublicRoute';
import { getMe } from "../../actions/user";

const mapStateToProps = state => ({
    isLogged: state.user.isLogged,
    authToken: state.user.authToken,
    userData: state.user.userData,

    isLoader: state.ui.isScreenLoader,
});

const mapDispatchToProps = {
    getMe,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute);
