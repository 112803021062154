import { getImage } from "../helpers/images";

export const slides = [
    'Aeolus',
    'Guitaret',
    'Retro-essentials',
    '80s-analog-hits-2',
    'Arco-classico-2',
    'Black-ivory-2',
    'Neon-2',
].map(elem => getImage(`slider/1/${elem}.webp`));

export const resizeDelay = 1; // delay in seconds after resizing window to redraw slider
export const startSlideIndex = 2; // an index of the image from the list above
export const autoSlideDefaultInterval = 3; // in seconds, set to 0 to disable

