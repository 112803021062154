const prefix = 'api/v3/';
export const REGISTER = `${prefix}auth/register`;
export const RESEND_EMAIL_VERIFICATION = `${prefix}auth/resend-email-verification`;
export const LOGIN = `${prefix}auth/login`;
export const ME = `${prefix}auth/me`;
export const LOGOUT = `${prefix}auth/logout`;
export const FORGOT = `${prefix}auth/password-recovery`;
export const RESET = `${prefix}auth/password-reset`;
export const CHANGE_PASSWORD = `${prefix}auth/password-change`;
export const STRIPE_CHECKOUT_CREATE = `${prefix}payments/checkout`;
export const STRIPE_CHECKOUT_BUNDLE_CREATE = `${prefix}payments/checkout/bundle/`;
export const VOUCHER_CODE_REDEEM = `${prefix}vouchers/redeem`;
export const STRIPE_GET_SESSION = `${prefix}payments/checkout/`;
export const SHARED_PACKS = `${prefix}sounds/content/packs`;
export const CUSTOMER_PORTAL_CREATE = `${prefix}payments/customerPortal`;
export const DOWNLOAD_SOUND = `${prefix}sounds/download/`;
export const GET_PACK = `${prefix}sounds/pack/`;
export const STYLES = `${prefix}sounds/styles`;
export const PLANS = `${prefix}plans`;
export const HEALTHCHECK = `${prefix}healthcheck`;
