import {POST} from "../constants/apiRequestTypes";
import {getMe} from "./user";
import Tap from "@tapfiliate/tapfiliate-js";
import { STATUS_TRIALING } from "../constants/subscription";
import {isTapfiliate} from "../config/app";
import localStorage from '../storages/localStorage';
import {META_EVENT_NAME_PURCHASE, trackBundleEvent} from "../helpers/meta-pixel";

export const CHECKOUT_PENDING = 'CHECKOUT_PENDING';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_ERROR   = 'CHECKOUT_ERROR';

export const BUNDLE_CHECKOUT_STORAGE_KEY = 'BUNDLE_CHECKOUT_STORAGE_KEY';

export const checkout = (packId) => dispatch => {
    return dispatch({
        type: POST,
        packId: packId,
        onPending: CHECKOUT_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: CHECKOUT_SUCCESS,
                response
            });
        },
        onFailure: CHECKOUT_ERROR,
        promise: (api) => {
            localStorage.remove(BUNDLE_CHECKOUT_STORAGE_KEY);

            return api.checkoutCreate(packId);
        },
    })
};

export const checkoutBundle = (stripePriceId) => dispatch => {
    return dispatch({
        type: POST,
        stripePriceId: stripePriceId,
        onPending: CHECKOUT_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: CHECKOUT_SUCCESS,
                response
            });
        },
        onFailure: CHECKOUT_ERROR,
        promise: (api) => {
            // since there is no way to know if a bundle was purchased or a single pack
            // after successful checkout session from API response.
            // We need to know to track it with meta pixel
            localStorage.set(BUNDLE_CHECKOUT_STORAGE_KEY, 1);

            return api.checkoutBundleCreate(stripePriceId);
        },
    })
};

export const BUNDLE_CHECKOUT_AFTER_REGISTRATION = 'BUNDLE_CHECKOUT_AFTER_REGISTRATION';
export const checkoutBundleAfterRegistration = (stripePriceId) => dispatch => {
    return dispatch({
        type: BUNDLE_CHECKOUT_AFTER_REGISTRATION,
        stripePriceId: stripePriceId,
    })
};

export const BUNDLE_CHECKOUT_AFTER_REGISTRATION_COMPLETE = 'BUNDLE_CHECKOUT_AFTER_REGISTRATION_COMPLETE';
export const checkoutBundleAfterRegistrationComplete = () => dispatch => {
    return dispatch({
        type: BUNDLE_CHECKOUT_AFTER_REGISTRATION_COMPLETE,
    })
};

export const STRIPE_GET_SESSION_PENDING = 'STRIPE_GET_SESSION_PENDING';
export const STRIPE_GET_SESSION_SUCCESS = 'STRIPE_GET_SESSION_SUCCESS';
export const STRIPE_GET_SESSION_ERROR   = 'STRIPE_GET_SESSION_ERROR';

export const stripeGetSession = (sessionId, getRedirectTo, history, userData) => dispatch => {
    return dispatch({
        type: POST,
        onPending: STRIPE_GET_SESSION_PENDING,
        onSuccess: (response) => {
            const result = dispatch({
                type: STRIPE_GET_SESSION_SUCCESS,
                response
            });

            dispatch(getMe(getRedirectTo ? getRedirectTo(response) : undefined, history));

            if (isTapfiliate) {
                console.log("Tapfiliate request should be performed");
            }

            if (isTapfiliate && response && typeof response.data.subscription_status !== "undefined" && response.data.subscription_status == STATUS_TRIALING && userData && userData.stripe_user_id) {
                try {
                    console.log(`Tapfiliate trial request is about to be sent with stripe_user_id#${userData.stripe_user_id}`);

                    Tap.trial(userData.stripe_user_id);
                } catch (err) {
                    console.error(err);
                }
            }

            if (localStorage.get(BUNDLE_CHECKOUT_STORAGE_KEY)) {
                trackBundleEvent(META_EVENT_NAME_PURCHASE);

                localStorage.remove(BUNDLE_CHECKOUT_STORAGE_KEY);
            }

            return result;
        },
        onFailure: (response) => {
            const result = dispatch({
                type: STRIPE_GET_SESSION_ERROR,
                error: response.error,
            });

            dispatch(getMe('/dashboard', history));

            return result;
        },
        promise: (api) => {
            return api.stripeGetSession(sessionId);
        },
    })
};

export const CUSTOMER_PORTAL_PENDING = 'CUSTOMER_PORTAL_PENDING';
export const CUSTOMER_PORTAL_SUCCESS = 'CUSTOMER_PORTAL_SUCCESS';
export const CUSTOMER_PORTAL_ERROR   = 'CUSTOMER_PORTAL_ERROR';

export const customerPortal = () => dispatch => {
    return dispatch({
        type: POST,
        onPending: CUSTOMER_PORTAL_PENDING,
        onSuccess: (response) => {
            return dispatch({
                type: CUSTOMER_PORTAL_SUCCESS,
                response
            });
        },
        onFailure: CUSTOMER_PORTAL_ERROR,
        promise: (api) => {
            return api.customerPortalCreate();
        },
    })
};

export const PAYMENT_CANCEL = 'PAYMENT_CANCEL';
export const paymentCancel = () => dispatch => dispatch({
    type: PAYMENT_CANCEL,
});

export const PAYMENT_CANCEL_SHOWN = 'PAYMENT_CANCEL_SHOWN';
export const paymentCancelShown = () => dispatch => dispatch({
    type: PAYMENT_CANCEL_SHOWN,
});


export const PORTAL_CANCEL = 'PORTAL_CANCEL';
export const portalCancel = () => dispatch => dispatch({
    type: PORTAL_CANCEL,
});

export const PORTAL_CANCEL_SHOWN = 'PORTAL_CANCEL_SHOWN';
export const portalCancelShown = () => dispatch => dispatch({
    type: PORTAL_CANCEL_SHOWN,
});

export const CLEAR_PAYMENT_RESULT = 'CLEAR_PAYMENT_RESULT';
export const clearPaymentResult = () => dispatch => dispatch({
    type: CLEAR_PAYMENT_RESULT,
});
