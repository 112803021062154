import axios from 'axios';
import * as apiRequestTypes from '../constants/apiRequestTypes';

export default class httpRequest {
    httpLib = axios;

    get = (url, params, token) => {
        const esc = encodeURIComponent;
        let query = '';

        if (params) {
            params['debug'] = 1;
            query = Object.keys(params)
                .map(k => esc(k) + '=' + esc(params[k]))
                .join('&');
        }

        return this.send('get',`${url}?${query}`, {}, token);
    };
    post = (url, params, token) => {
        return this.send('post', url, this.buildParams(params), token);
    };
    put = (url, params, token) => {
        return this.send('put', url, this.buildParams(params), token);
    };
    delete = (url, params, token) => {
        return this.send('delete', url, params, token);
    };

    buildParams = (params) => {
        let data = new FormData();

        if (!params) {
            params = {};
        }
        params['debug'] = 1;
        Object.keys(params).forEach(key => {
            data.append(key, params[key]);
        });

        return data;
    };

    send = (method, url, data, token, additionalHeaders) => {
        let headers = {'Content-Type': 'application/x-www-form-urlencoded' };

        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }

        if (additionalHeaders) {
            headers = {...headers, ...additionalHeaders};
        }

        let options = {
            method: method,
            url: url,
            data: data,
            headers: headers
        };


        if (data) {
            options.data = data;
        }

        return this.httpLib(options);
    };

    request = (url, type, params, token) => {
        switch (type) {
            case apiRequestTypes.GET:
                return this.get(url, params, token);
            case apiRequestTypes.POST:
                return this.post(url, params, token);
            case apiRequestTypes.PUT:
                return this.put(url, params, token);
            case apiRequestTypes.DELETE:
                return this.delete(url, params, token);
            default:
                return false;
        }
    };
}
