import { connect } from 'react-redux';
import Route from '../../components/routes/Route';
import { setVerificationStatus, getMe } from '../../actions/user';
import { healthCheck } from '../../actions/system';
import { getPlans } from '../../actions/plans';
import { isPlansRequest, getMonthPlan } from '../../selectors/plans';

const mapDispatchToProps = {
    setVerificationStatus,
    getMe,
    getPlans,
    healthCheck,
};

const mapStateToProps = state => ({
    isNeedUpdate: state.user.isNeedUpdate,
    authToken: state.user.authToken,
    userData: state.user.userData,
    isLogged: state.user.isLogged,
    isScreenLoader: state.ui.isScreenLoader,
    isInternalError: state.ui.isInternalError,
    isNeedPlansRequest: isPlansRequest(state),
    monthPlan: getMonthPlan(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Route);
