import { autoSlideDefaultInterval } from '../config/slider';

export const SLIDE_NEXT   = 'SLIDE_NEXT';
export const SLIDE_PREV   = 'SLIDE_PREV';
export const START_SLIDER = 'START_SLIDER';
export const STOP_SLIDER  = 'STOP_SLIDER';

export const slideNext = (name) => dispatch => dispatch({
    type: SLIDE_NEXT,
    name,
});
export const slidePrev = (name) => dispatch => dispatch({
    type: SLIDE_PREV,
    name,
});

let sliderTimer = {};

export const startSlider = (name) => dispatch => {
    clearInterval(sliderTimer[name]);

    sliderTimer[name] = setInterval(() => {
        dispatch(slideNext(name))
    }, autoSlideDefaultInterval * 1000);

    dispatch({
        type: START_SLIDER,
        name,
    });
};

export const stopSlider = (name) => dispatch => {
    clearInterval(sliderTimer[name]);

    dispatch({
        type: STOP_SLIDER,
    });
};
