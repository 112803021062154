import { POST } from "../constants/apiRequestTypes";
import { getMeSilent } from "./user";

export const VOUCHER_CODE_REDEMPTION_PENDING = 'VOUCHER_CODE_REDEMPTION_PENDING';
export const VOUCHER_CODE_REDEMPTION_SUCCESS = 'VOUCHER_CODE_REDEMPTION_SUCCESS';
export const VOUCHER_CODE_REDEMPTION_ERROR   = 'VOUCHER_CODE_REDEMPTION_ERROR';

export const voucherCodeRedeem = (voucherCode) => dispatch => {
    return dispatch({
        type: POST,
        voucherCode: voucherCode,
        onPending: VOUCHER_CODE_REDEMPTION_PENDING,
        onSuccess: (response) => {
            dispatch({
                type: VOUCHER_CODE_REDEMPTION_SUCCESS,
                response
            });

            return dispatch(getMeSilent());
        },
        onFailure: VOUCHER_CODE_REDEMPTION_ERROR,
        promise: (api) => {
            return api.voucherCodeRedeem(voucherCode);
        },
    })
};
