import { GET_ME_SUCCESS, needUpdateDelayed } from '../actions/user';

const appMiddleware = ({dispatch}) => {
    return next => action => {
        const { type, ...rest } = action;

        next(action);

        switch (type) {
            case GET_ME_SUCCESS:
                if (rest.redirectTo) {
                    rest.history.push(rest.redirectTo);
                }

                dispatch(needUpdateDelayed());
                break;
            default:
                break;
        }
    }
};

export default appMiddleware;
