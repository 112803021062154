import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import initialReducers from '../reducers';
import { composeWithDevTools } from 'redux-devtools-extension';
import appMiddleware from '../middlewares/appMiddleware';
import localStorageMiddleware from '../middlewares/localStorageMiddleware';
import apiMiddleware from '../middlewares/apiMiddleware';
import stripeMiddleware from '../middlewares/stripeMiddleware';
import delayedActionsMiddleware from '../middlewares/delayedActionsMiddleware';
import googleAnalyticsMiddleware from '../middlewares/googleAnalyticsMiddleware';
import httpAPIClient from '../api/httpAPIClient';

const APIClient = new httpAPIClient();

const configureStore = (isProduction) => {
    let middlewares;

    const initialMiddlewares = [
        thunk,
        apiMiddleware(APIClient),
        localStorageMiddleware,
        stripeMiddleware,
        appMiddleware,
        delayedActionsMiddleware,
        googleAnalyticsMiddleware,
    ];

    if (isProduction) {
        middlewares = applyMiddleware(...initialMiddlewares);
    } else {
        const logger = createLogger({collapsed: true});

        middlewares = composeWithDevTools(applyMiddleware(...initialMiddlewares, ...[
            logger,
        ]));
    }

    const store = createStore(
        combineReducers(initialReducers),
        middlewares
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            store.replaceReducer(initialReducers)
        })
    }

    return store
};

export default configureStore;
