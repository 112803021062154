import { createSelector } from 'reselect';
import { formatPlan } from '../helpers/plans';

const getPlans = state => state.plans;

export const isPlansRequest = createSelector(
    [getPlans],
    (plans) => {
        return plans.plans.length === 0 || !plans.isPlansRequested;
    }
);

const fallbackPlan = {
    price: process.env.REACT_APP_SUBSCRIPTION_PLAN_MONTHLY_FALLBACK_PRICE,
    trial_duration: process.env.REACT_APP_SUBSCRIPTION_PLAN_MONTHLY_FALLBACK_TRIAL_PERIOD,
};
export const getMonthPlan = createSelector(
    [getPlans],
    (plans) => {
        if (plans.plans.length === 0) {
            return fallbackPlan;
        }

        const orderedPlans = plans.plans.filter(plan => {
            return +plan.id === +process.env.REACT_APP_SUBSCRIPTION_PLAN_MONTHLY_ID;
        }).sort((a, b) => {
            if (a.id < b.id) {
                return -1;
            }

            return 1;
        });

        return formatPlan(orderedPlans.length === 1 ? orderedPlans[0] : fallbackPlan);
    }
);
