import { lazy } from 'react';
import { freeTrialLink, downloadLink, featuresRoute, landingRoute } from './app';
import { taglineText } from './texts';
import { isLandingPageEnabled, isLandingPageDefaultRoute, saleDateEnd } from './landing';
import { formatBundleSaleDate } from '../helpers/utils';

const Features = lazy(() => import('../containers/Features'));
const Landing = lazy(() => import('../containers/Landing'));
const Download = lazy(() => import('../containers/Download'));
const Pricing = lazy(() => import('../containers/Pricing'));

const Terms = lazy(() => import('../containers/Terms'));
const SignUp = lazy(() => import('../containers/SignUp'));
const Login = lazy(() => import('../containers/Login'));
const Forgot = lazy(() => import('../containers/Forgot'));
const PasswordReset = lazy(() => import('../containers/PasswordReset'));
const ChangePassword = lazy(() => import('../containers/ChangePassword'));

const Dashboard = lazy(() => import('../containers/Dashboard'));

const PaymentSuccess = lazy(() => import('../containers/PaymentSuccess'));
const PaymentCancel = lazy(() => import('../containers/PaymentCancel'));
const PortalCancel = lazy(() => import('../containers/PortalCancel'));
const Catalog = lazy(() => import('../containers/Catalog'));
const FreeTrial = lazy(() => import('../containers/FreeTrial'));
const Changelog = lazy(() => import('../containers/Changelog'));
const Page404 = lazy(() => import('../containers/Page404'));

export const titles = {
    features: `${taglineText} - try it free`,
    freeTrial: `${taglineText} - Try it free`,
    dashboard: 'Dashboard',
    landing: 'Black Friday Deal: Everything Bundle 2023',
    download: 'Download Portal and the Capsule plugin',
    pricing: 'Subscription plans and licenses',
    terms: 'Terms & Conditions',
    privacy: 'Privacy',
    signUp: 'Sign Up',
    login: 'Log In',
    passwordReset: 'Reset Password',
    forgot: 'Forgot Password',
    paymentSuccess: 'Payment Succeeded',
    paymentCancel: 'Payment Canceled',
    portalCancel: 'Customer Portal',
    catalog:  'Curated sound Capsules for your DAW',
    changePassword: 'Change Password',
    pageNotFound: 'Page not found',
    isInternalError: 'Internal error',
};

export const signUpPartnerRoute = '/signup-partner';

export const defaultRoute = isLandingPageEnabled && isLandingPageDefaultRoute ? landingRoute : featuresRoute;

let routes = [
    {
        path: featuresRoute,
        component: Features,
        isProtected: false,
        title: titles.features,
        description: 'Unlock Capsule, a simple yet powerful instrument plugin, endlessly updated with fresh sounds, themes, and ideas.',
    },
    {
        path: `${freeTrialLink}/:subscriptionPlanId?`,
        component: FreeTrial,
        isProtected: true,
        redirectUnloggedTo: `/signup${freeTrialLink}`,
        title: titles.freeTrial,
        // description for this route generates in
        // the components/routes/Route.js file, because it requires
        // to obtain a plan from the BE
        description: '',
    },
    {
        path: '/dashboard/:paymentResult?/:sessionId?',
        component: Dashboard,
        isProtected: true,
        exact: false,
        title: titles.dashboard,
        description: 'Manage your account and Capsule subscription',
    },
    {
        path: downloadLink,
        component: Download,
        isProtected: false,
        title: titles.download,
        description: 'Install the Capsule Portal desktop application and the Capsule plugin.',
    },
    {
        path: '/pricing',
        component: Pricing,
        isProtected: false,
        title: titles.pricing,
        description: 'Access hundreds of high-end playable sounds via our Capsule instrument plugin. Starting at $9.99/mo.',
    },
    {
        path: '/terms',
        component: Terms,
        exact: true,
        isProtected: false,
        title: titles.terms,
        description: 'Service terms and conditions',
    },
    {
        path: '/privacy',
        component: Terms,
        exact: true,
        isProtected: false,
        title: titles.privacy,
        description: 'Privacy policy information',
    },
    {
        path: '/signup/:redirectTo?/:planId?',
        component: SignUp,
        isHeader: false,
        isProtected: false,
        title: titles.signUp,
        description: 'Signup for Capsule, sounds unlocked.',
    },
    {
        path: `${signUpPartnerRoute}/:redirectTo?/:planId?`,
        component: SignUp,
        isHeader: false,
        isProtected: false,
        title: titles.signUp,
        description: 'Signup for Capsule, sounds unlocked.',
    },
    {
        path: '/login/:redirectTo?/:planId?',
        component: Login,
        isHeader: false,
        isProtected: false,
        title: titles.login,
        description: 'Log in to Capsule, sounds unlocked.',
    },
    {
        path: '/reset-password/:token?/:email?',
        component: PasswordReset,
        isHeader: false,
        isProtected: false,
        title: titles.passwordReset,
        description: 'Reset the password for your Capsule account',
    },
    {
        path: '/forgot',
        component: Forgot,
        isHeader: false,
        isProtected: false,
        title: titles.forgot,
        description: 'Forgot password page',
    },
    {
        path: '/payment-success/:sessionId',
        component: PaymentSuccess,
        exact: false,
        isProtected: true,
        title: titles.paymentSuccess,
        description: 'Item purchase was successful',
    },
    {
        path: '/payment-cancel',
        component: PaymentCancel,
        isProtected: true,
        title: titles.paymentCancel,
        description: 'Item purchase has failed',
    },
    {
        path: '/portal-cancel',
        component: PortalCancel,
        isProtected: true,
        title: titles.portalCancel,
        description: 'Return from customer portal',
    },
    {
        path: '/catalog',
        component: Catalog,
        isProtected: true,
        title: titles.catalog,
        description: 'Browse our Capsule lineup, including hundreds of high-end playable sounds to use in your music productions. Licenses starting at $19.99.',
    },
    {
        path: '/change-password',
        component: ChangePassword,
        isHeader: false,
        title: titles.changePassword,
        description: 'Change the password for your Capsule account',
    },
    {
        path: '/changelog/:version?',
        component: Changelog,
        isHeader: false,
        isProtected: false,
        // there are another title/description pair in the component
        title: "What's new",
        description: 'Changelog for the Capsule Portal desktop application and Capsule audio Plugin.',
    },
];

if (isLandingPageEnabled) {
    routes.push({
        path: landingRoute,
        component: Landing,
        isProtected: false,
        title: titles.landing,
        description: `Seize this limited-time opportunity to own Capsule's entire catalog of virtual instruments at a massive discount. This offer is available only till ${formatBundleSaleDate(new Date(saleDateEnd))}.`,
    });
}
// this is just to keep all titles and paths for analytics in one place
export const logoutAnaliticsRoute = {
    path: 'logout',
    title: 'Log Out',
}

export const route404 = {
    path: '',
    component: Page404,
    isProtected: false,
    title: titles.pageNotFound,
    description: 'Page not found',
};
export default routes;
